import React, { useContext, useState } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import ProductsStockDataTabelContainer from "./ProductsStockDataTabelContainer";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  PRODUCT,
  PRODUCT_STOCK_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import ProductsStockContext from "../../../../context/ProductsStockContext/ProductsStockContext";
import { get_Products_Stock } from "../../../../Apis/productsStockApi/ProductTagMasterStockApi";

const ProductsStock = () => {
  const { setRecord, sortOrder, sortBy, filter } =
    useContext(ProductsStockContext);

  const history = useNavigate();

  let [pagination, setPagination] = useState({
    first: 0,
    page: 0,
    pageCount: 10,
  });

  /**
   * get products stock List
   */

  let paramsObj = {
    pageNumber: pagination.page + 1,
    recordsPerPage: pagination.pageCount,
    sortOrder,
    sortBy,
  };
  if (filter?.length) {
    paramsObj["search"] = { productName: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getProductsStock", paramsObj],
    async () => await get_Products_Stock(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  return (
    <>
      <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_STOCK_LIST} />
      <ProductsStockDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        setPagination={setPagination}
        pagination={pagination}
      />
    </>
  );
};

export default ProductsStock;
