import React from "react";
import { FallingLines } from "react-loader-spinner";

const Loader = () => {
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    top: 0,
    height: "100vh",
    zIndex: "9999",
    width: "100vw",
    backgroundColor: "white",
  };
  return (
    <div className="loader" style={style}>
      <FallingLines
        color="#4fa94d"
        width="100"
        visible={true}
        ariaLabel="falling-lines-loading"
      />
    </div>
  );
};

export default Loader;
