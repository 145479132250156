import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import DetailsMasterPageDataTabelContainer from "./DetailsMasterPageDataTabelContainer";
import DetailsMasterPageCreateFormPopup from "./DetailsMasterPageCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  DETAILS_PAGE,
  DETAILS_MASTER_PAGE_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import {
  CreateDetailsMasterPage,
  UpdateDetailsMasterPage,
} from "../../../../constants";
import DetailsMasterPageContext from "../../../../context/DetailsMasterPageContext/DetailsMasterPageContext";
import { get_Details_Master_Page } from "../../../../Apis/detailsMasterPageApi/DetailsMasterPageApi";

const DetailsMasterPage = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //details master page edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
  } = useContext(DetailsMasterPageContext);

  const history = useNavigate();

  const emptyField = () => {
    setFormData({
      title: "",
      order: "",
    });
  };

  /**
   * get details master page list
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { title: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getDetailsMasterPage", paramsObj],
    async () => await get_Details_Master_Page(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup details master page
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyField();
    } else {
      setdialogs(condition);
      emptyField();
    }
  };
  return (
    <>
      <Breadcrumb
        name={DETAILS_PAGE}
        slace={SLACE}
        name1={DETAILS_MASTER_PAGE_LIST}
      />
      <DetailsMasterPageDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateDetailsMasterPage : UpdateDetailsMasterPage}
        Component={
          dialog && (
            <DetailsMasterPageCreateFormPopup
              refetch={refetch}
              emptyField={emptyField}
            />
          )
        }
      />
    </>
  );
};

export default DetailsMasterPage;
