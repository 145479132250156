import { Dialog } from "primereact/dialog";
import React from "react";
import ChropeImage from "../public/CropeImage";
import { checkImageType } from "../utils/Helper";

const CropImagePopup = (props) => {
  const {
    dialogModal,
    setdialogsModal,
    image,
    aspect,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    setFormData,
    uploadImage,
  } = props;

  return (
    image && (
      <Dialog
        header="Header"
        visible={dialogModal}
        onHide={() => {
          setdialogsModal(false);
          setFormData((elem) => {
            return {
              ...elem,
              logo: "",
            };
          });
        }}
        draggable={false}
        style={{ width: "50vw" }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {image && checkImageType(image) === "base64" && (
          <ChropeImage
            imageWidth={66}
            aspect={aspect}
            image={image}
            crop={crop}
            setCrop={setCrop}
            completedCrop={completedCrop}
            setCompletedCrop={setCompletedCrop}
            scale={scale}
            rotate={rotate}
            setImage={setFormData}
            setAllPopupState={setdialogsModal}
            imageUpload={uploadImage}
          />
        )}
      </Dialog>
    )
  );
};

export default CropImagePopup;
