import axios from "axios";
import {
  CREATE_PINCODE_MASTER,
  DELETE_PINCODE_MASTER,
  EDIT_PINCODE_MASTER,
  ENABLE_DISABLE_PINCODE_MASTER,
  GET_PINCODE_MASTER,
  GET_PINCODE_MASTER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get pincode master list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Pincode_Master = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PINCODE_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add pincode master api call
 * @param {form data} formData
 * @returns
 */

export const Create_Pincode_Master = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_PINCODE_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable pincode master api call
 * @param {form data as isActive & pincodeMasterId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Pincode_Master = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_PINCODE_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { pincodeMasterId: formData.pincodeMasterId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details pincode master
 * @param {*} pincodeMasterId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Pincode_Master = (
  pincodeMasterId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PINCODE_MASTER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { pincodeMasterId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      setFormData({
        pincode: data?.pincode ? data?.pincode : "",
        city: data?.city ? data?.city : "",
        state: data?.state ? data?.state : "",
        county: data?.county ? data?.county : "",
        isAll: data?.isAll ? data?.isAll : false,
      });
      setdialogs(true);
    });
};

/**
 * update pincode master api call
 * @param {form data} formData
 * @param {*} pincodeMasterId
 * @returns
 */

export const Put_Update_Pincode_Master = (formData, pincodeMasterId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_PINCODE_MASTER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { pincodeMasterId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete pincode master api call
 * @param {form data} formData
 * @param {*} pincodeMasterId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Pincode_Master = (pincodeMasterId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_PINCODE_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: { pincodeMasterId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
