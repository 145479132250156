import axios from "axios";
import {
  CHECK_SLUG_BRAND_MASTER,
  CREATE_BRAND_MASTER,
  DELETE_BRAND_MASTER,
  EDIT_BRAND_MASTER,
  ENABLE_DISABLE_BRAND_MASTER,
  GET_BRAND_MASTER,
  GET_BRAND_MASTER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get brand master list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Brand_Master = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_BRAND_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add brand master api call
 * @param {form data} formData
 * @returns
 */

export const Create_Brand_Master = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_BRAND_MASTER}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable brand master api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Brand_Master = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_BRAND_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details brand master
 * @param {*} brandId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Brand_Master = (brandId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_BRAND_MASTER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { brandId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      setFormData({
        brandName: data ? data?.brandName : null,
        brandDesc: data ? data?.brandDesc : null,
        slugUrl: data ? data?.slugUrl : null,
        image: data ? data?.logo : null,
      });
      setdialogs(true);
    });
};

/**
 * update brand master api call
 * @param {form data} formData
 * @param {*} brandId
 * @returns
 */

export const Put_Update_Brand_Master = (formData, brandId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_BRAND_MASTER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { brandId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete brand master api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Brand_Master = (brandId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_BRAND_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: { brandId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug brand master api call
 * @param {form data} formData
 * @param {*} userId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_BRAND_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {
      // if (message.status === 200) {
      //   ToastMessage(message.status, message.data.message);
      // }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
