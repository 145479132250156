import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import UserContext from "../../../../context/UserContext/UserContext";
import { Role, TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import {
  DELETE_USER,
  Enable_Disable_User,
  Get_Details_User,
} from "../../../../Apis/userApi/UserApi";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { Eye } from "react-feather";
import { Link } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";

const UserDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(UserContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get user Details
   */
  const { mutate: userDetails, isLoading: isLoadingUserDetails } = useMutation(
    (userId) => Get_Details_User(userId, setFormData, setdialogs)
  );

  const GetUserDetails = (userId) => {
    userDetails(userId);
    setOrgId(userId);
    setIsEdit(false);
  };

  /**
   * delete user
   */
  const { mutate: userDelete, isLoading: isLoadingDelete } = useMutation(
    (userId) => DELETE_USER(userId, refetch)
  );

  const DeleteUser = async (userId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      userDelete(userId);
    }
  };

  /**
   * enable / disable user
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_User(formData, refetch)
  );

  const handleUserStatusChange = (userId, status) => {
    let formDate = {
      userId: userId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.userId === userId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "image",
      headerName: "Image",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={
            params.row.image
              ? params.row.image
              : require("../../../../assets/Images/ImageNotFound.png")
          }
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "phone",
      headerName: "Phone",
      renderCell: (params) =>
        params.row.phone ? params.row.dialCode + params.row.phone : "--",
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "roleId",
      headerName: "Role",
      renderCell: (params) =>
        params.row.roleId === Role.SuperAdmin ? (
          <div
            style={{
              background: "#ffabab",
              padding: "6px",
              borderRadius: "9px",
              color: "#ad1515",
            }}
          >
            {"Super Admin"}
          </div>
        ) : params.row.roleId === Role.Store_Admin ? (
          <div
            style={{
              background: "#f6de95",
              padding: "6px",
              borderRadius: "9px",
              color: "#816204",
            }}
          >
            {"Store Admin"}
          </div>
        ) : params.row.roleId === Role.Delivery_Boy ? (
          <div
            style={{
              background: "#a0e6ba",
              padding: "6px",
              borderRadius: "9px",
              color: "#136c34",
            }}
          >
            {"Delivery Boy"}
          </div>
        ) : params.row.roleId === Role.Customer ? (
          <div
            style={{
              background: "#f7b0d3",
              padding: "6px",
              borderRadius: "9px",
              color: "#822854",
            }}
          >
            {"Customer"}
          </div>
        ) : (
          "--"
        ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleUserStatusChange(params.row.userId, params.row.isActive)
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetUserDetails(params.row.userId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <Link to={`${REDIRECT_PATH.USER}/detail/${params.row.userId}`}>
                <IconButton aria-label="edit">
                  <Eye style={{ color: "#7366ff" }} />
                </IconButton>
              </Link>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteUser(params.row.userId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingUserDetails || isLoadingDelete} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.userId}
        addButton={true}
      />
    </Fragment>
  );
};

export default UserDataTabelContainer;
