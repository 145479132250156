import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  Name,
  Address,
  LATITUDE,
  LONGITUDE,
  User,
  Role,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createStoreSchema } from "../../../../utils/validationSchema";
import StoreContext from "../../../../context/StoreContext/StoreContext";
import {
  Create_Store,
  Put_Update_Store,
} from "../../../../Apis/storeApi/StoreApi";
import { get_User } from "../../../../Apis/userApi/UserApi";

const StoreCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(StoreContext);
  const { refetch, emptyFormData } = props;
  const [filterDrop, setFilterDrop] = useState({
    userFilter: "",
  });

  /**
   *
   * @param {event } e
   * create store & update store
   */

  const { mutate: createStore, isLoading: createStoreLoader } = useMutation(
    (formData) => Create_Store(formData, refetch, setdialogs, emptyFormData)
  );

  const { mutate: updateStore, isLoading: updateStoreLoader } = useMutation(
    (formData) =>
      Put_Update_Store(formData, id, refetch, setdialogs, emptyFormData)
  );

  const handleStoreCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createStoreSchema();
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          assignUsers: formData?.assignUsers?.length
            ? formData?.assignUsers.map((elem) => {
                return {
                  userId: elem?.code,
                };
              })
            : null,
        };
        updateStore(newData);
      } else {
        let newData = {
          ...formData,
          assignUsers: formData?.assignUsers?.length
            ? formData?.assignUsers.map((elem) => {
                return {
                  userId: elem?.code,
                };
              })
            : null,
        };
        createStore(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeStore = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  let paramsObj = {
    showAll: true,
    search: {
      name: filterDrop.userFilter,
      roleId: [Role.SuperAdmin, Role.Store_Admin],
    },
  };
  let { data: userDrop } = useQuery(
    ["getUserDropdown", paramsObj],
    async () => await get_User(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  return (
    <Fragment>
      <IsLoadingResponse isLoading={createStoreLoader || updateStoreLoader} />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleStoreCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={Name}
                      name="name"
                      id="name"
                      type={TYPE_FELDS.text}
                      placeholder={Name}
                      value={formData.name}
                      handleChange={handleChangeStore}
                    />
                    {errors.name && (
                      <span className="error-validation">{errors.name}</span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={User}
                      name="assignUsers"
                      id="assignUsers"
                      placeholder={User}
                      value={formData.assignUsers}
                      handleChange={handleChangeStore}
                      multiDropdown={true}
                      multiOption={userDrop?.payload?.data?.map((elem) => {
                        return {
                          name: elem?.name,
                          code: elem?.userId,
                        };
                      })}
                      search={(e) => {
                        setFilterDrop((prev) => {
                          return {
                            ...prev,
                            userFilter: e.target.value,
                          };
                        });
                      }}
                    />
                    {errors.assignUsers && (
                      <span className="error-validation">
                        {errors.assignUsers}
                      </span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={Address}
                      name="address"
                      id="address"
                      type={TYPE_FELDS.text}
                      placeholder={Address}
                      value={formData.address}
                      handleChange={handleChangeStore}
                    />
                    {errors.address && (
                      <span className="error-validation">{errors.address}</span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={LATITUDE}
                      name="latitude"
                      id="latitude"
                      type={TYPE_FELDS.text}
                      placeholder={LATITUDE}
                      value={formData.latitude}
                      handleChange={handleChangeStore}
                    />
                    {errors.latitude && (
                      <span className="error-validation">
                        {errors.latitude}
                      </span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={LONGITUDE}
                      name="longitude"
                      id="longitude"
                      type={TYPE_FELDS.text}
                      placeholder={LONGITUDE}
                      value={formData.longitude}
                      handleChange={handleChangeStore}
                    />
                    {errors.longitude && (
                      <span className="error-validation">
                        {errors.longitude}
                      </span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={createStoreLoader || updateStoreLoader}
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default StoreCreateFormPopup;
