import Swal from "sweetalert2";

const SweetConfirmation = () => {
  return Swal.fire({
    title: "Are you sure?",
    text: "You won't to delete!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    return {
      dismiss: result.dismiss,
      isConfirmed: result.isConfirmed,
      isDenied: result.isDenied,
      isDismissed: result.isDismissed,
    };
  });
};

export default SweetConfirmation;
