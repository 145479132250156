import React, { useState } from "react";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  STORE,
  STORE_CLUSTER,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { GoogleMap, Polygon, useLoadScript } from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import { BackButton } from "../../../../reuseableComponents/buttons";

const StoreCluster = () => {
  const history = useNavigate();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAp5NJa0SAKm2pvfb3OaO7Q8asUfV_yGFs",
  });

  const [paths, setPaths] = useState([]);

  const handleMapClick = (event) => {
    // Add the clicked point to the paths
    const clickedPoint = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    setPaths((prevPaths) => [...prevPaths, clickedPoint]);
  };

  const handlePolygonComplete = () => {
    // Handle the completion of the polygon if needed
    setPaths(paths);
  };

  const handlePolygonClick = (pathIndex) => {
    // Remove the clicked point from the paths
    setPaths((prevPaths) =>
      prevPaths.filter((_, index) => index !== pathIndex)
    );
  };
  return (
    <>
      <Breadcrumb name={STORE} slace={SLACE} name1={STORE_CLUSTER} />
      <BackButton history={history} path={REDIRECT_PATH.STORE} />
      <div className="tabel-contant">
        <div className={`tabelContent`}>
          {!isLoaded ? (
            <p>loading ... </p>
          ) : (
            <GoogleMap
              mapContainerClassName="map-container"
              mapContainerStyle={{ width: "100%", height: "70vh" }}
              center={{
                lat: paths[0]?.lat || 0,
                lng: paths[0]?.lng || 0,
              }}
              zoom={5}
              onClick={handleMapClick}
            >
              <Polygon
                paths={paths}
                editable
                draggable
                onMouseUp={handlePolygonComplete}
                onClick={(e) => {
                  // Handle click on the polygon to remove a point
                  const pathIndex = e.vertex;
                  if (pathIndex !== null) {
                    handlePolygonClick(pathIndex);
                  }
                }}
              />
            </GoogleMap>
          )}
        </div>
      </div>
    </>
  );
};

export default StoreCluster;
