import React, { Fragment, useContext, useState } from "react";
import {
  Rider,
  Ship_status,
  SHIP_STATUS1,
  Submit,
  TYPE_FELDS,
} from "../../../../constants";
import { useMutation, useQuery } from "react-query";
import { createOrderAssignRiderSchema } from "../../../../utils/validationSchema";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { get_Rider } from "../../../../Apis/riderApi/RiderApi";
import OrderContext from "../../../../context/OrderContext/OrderContext";
import {
  Create_Order_Assign_Rider,
  Order_update,
} from "../../../../Apis/orderApi/OrderApi";

const ShipingListupdate = (props) => {
  const {
    setdialogs,
    formData,
    errors,
    setErrors,
    setShippingStatus,
    setFormData,
  } = useContext(OrderContext);
  const { refetch, emptyField } = props;

  const [newData, setNewData] = useState();

  /**
   *
   * @param {event } e
   * create order assign rider & update order assign rider
   */

  const {
    mutate: createOrderAssignRider,
    isLoading: createOrderAssignRiderLoader,
  } = useMutation((formData) => Create_Order_Assign_Rider(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setdialogs(false);
        emptyField();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const handlePincodeMasterCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      const paramObj = {
        shipStatus: formData.shipStatus.code,
        orderId: formData.orderId,
      };
      const { data } = await Order_update(paramObj);
      setShippingStatus(false);
      setFormData({});
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeOrderAssignRider = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      {/* <IsLoadingResponse isLoading={} /> */}
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handlePincodeMasterCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="12 mb-4">
                    <FormDataFeild
                      label={Ship_status}
                      name="shipStatus"
                      id="shipStatus"
                      placeholder={Ship_status}
                      value={formData.shipStatus}
                      handleChange={handleChangeOrderAssignRider}
                      dropdown={true}
                      option={SHIP_STATUS1?.map((elem) => {
                        return {
                          code: elem?.Value,
                          name: elem?.lable || "--",
                        };
                      })}
                      search={(e) => setNewData(e.target.value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={createOrderAssignRiderLoader}
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ShipingListupdate;
