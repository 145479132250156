import React, { useState } from "react";
import RoomCategoryMasterContext from "./RoomCategoryMasterContext";

const RoomCategoryMasterState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**organization edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [categoryId, setCategoryId] = useState(null);
  const [formData, setFormData] = useState({
    categoryName: null,
    rate: null,
    deposit: 0,
    guestHouseId: null,
  });

  return (
    <RoomCategoryMasterContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        categoryId,
        setCategoryId,
        formData,
        setFormData,
      }}
    >
      {props.children}
    </RoomCategoryMasterContext.Provider>
  );
};

export default RoomCategoryMasterState;
