import { ProgressBar } from "primereact/progressbar";
import React, { useContext } from "react";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const Progressbar = ({ isLoadingTempleMasterDetails }) => {
  const { progressbar } = useContext(LoaderContext);
  return (
    progressbar && (
      <ProgressBar
        mode="indeterminate"
        style={{ height: "6px", zIndex: "99999" }}
      ></ProgressBar>
    )
  );
};

export default Progressbar;
