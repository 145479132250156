import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import PincodeMasterContext from "../../../../context/PincodeMasterContext/PincodeMasterContext";
import {
  Delete_Pincode_Master,
  Enable_Disable_Pincode_Master,
  Get_Details_Pincode_Master,
} from "../../../../Apis/pincodeMasterApi/PincodeMasterApi";
import { InputSwitch } from "primereact/inputswitch";

const PincodeMasterDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(PincodeMasterContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get pincode master Details
   */
  const {
    mutate: PincodeMasterDetails,
    isLoading: isLoadingPincodeMasterDetails,
  } = useMutation((pincodeMasterId) =>
    Get_Details_Pincode_Master(pincodeMasterId, setFormData, setdialogs)
  );

  const GetPincodeMasterDetails = (pincodeMasterId) => {
    PincodeMasterDetails(pincodeMasterId);
    setId(pincodeMasterId);
    setIsEdit(false);
  };

  /**
   * delete pincode master
   */
  const { mutate: PincodeMasterDelete, isLoading: isLoadingDelete } =
    useMutation((pincodeMasterId) =>
      Delete_Pincode_Master(pincodeMasterId, refetch)
    );

  const DeleteDeliveryOffDay = async (pincodeMasterId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      PincodeMasterDelete(pincodeMasterId);
    }
  };

  /**
   * enable / disable pincode master
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Pincode_Master(formData, refetch)
  );

  const handlePincodeMaster = (pincodeMasterId, status) => {
    let formDate = {
      pincodeMasterId: pincodeMasterId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.pincodeMasterId === pincodeMasterId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "pincode",
      headerName: "Pincode",
      renderCell: (params) => (params.row.pincode ? params.row.pincode : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "city",
      headerName: "City",
      renderCell: (params) => (params.row.city ? params.row.city : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "state",
      headerName: "State",
      renderCell: (params) => (params.row.state ? params.row.state : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "county",
      headerName: "Country",
      renderCell: (params) => (params.row.county ? params.row.county : "-"),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "isAll",
      headerName: "All",
      renderCell: (params) =>
        params.row.isAll ? (
          <p
            style={{
              color: "green",
              fontWeight: "900",
              letterSpacing: "0.5px",
            }}
            className="m-0"
          >
            Allow
          </p>
        ) : (
          <p
            style={{ color: "red", fontWeight: "900", letterSpacing: "0.5px" }}
            className="m-0"
          >
            Danny
          </p>
        ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handlePincodeMaster(
                params.row.pincodeMasterId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  GetPincodeMasterDetails(params.row.pincodeMasterId)
                }
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteDeliveryOffDay(params.row.pincodeMasterId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingPincodeMasterDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.pincodeMasterId}
        addButton={true}
        search={true}
      />
    </Fragment>
  );
};

export default PincodeMasterDataTabelContainer;
