export const SelectedAttribute = ({ elem, selectElem }) => {
  let finalValue = {};
  if (elem?.attributeId?.length) {
    for (const key in selectElem?.attValue) {
      for (const iterator of elem?.attributeId) {
        if (selectElem?.attValue[key]?.attributeId === iterator?.code) {
          finalValue = iterator;
        }
      }
    }
  }
  return {
    code: finalValue?.code,
    name: finalValue?.name,
    attributeType: finalValue?.attributeType,
  };
  //   return finalValue;
};

export const filterDropdown = (selectAttribute, selectedObj) => {
  let dropdown = [...selectAttribute];
  for (let i = 0; i < selectedObj.length; i++) {
    for (let j = 0; j < dropdown.length; j++) {
      // Check if attributeMasterId matches
      if (
        selectedObj[i].attributeId[j].attributeMasterId ===
        dropdown[j].categoryAtt.attributeMasterId
      ) {
        // Find the index of the option in attValue with a matching code
        const index = dropdown[j].attValue.findIndex(
          (option) => option.attributeId === selectedObj[i].attributeId[j].code
        );

        // If a matching option is found, remove it
        if (index !== -1) {
          dropdown[j].attValue.splice(index, 1);
        }

        // Check if isDefault is true and remove the option if it is
        if (selectedObj[i].isDefault) {
          const defaultIndex = dropdown[j].attValue.findIndex(
            (option) =>
              option.attributeId === selectedObj[i].attributeId[j].code
          );
          if (defaultIndex !== -1) {
            dropdown[j].attValue.splice(defaultIndex, 1);
          }
        }
      }
    }
  }
  return dropdown;
};
