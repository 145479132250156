import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { InputSwitch } from "primereact/inputswitch";
import DeliveryPincodeContext from "../../../../context/DeliveryPincodeContext/DeliveryPincodeContext";
import {
  Delete_Delivery_Pincode,
  Enable_Disable_Delivery_Pincode,
  Get_Details_Delivery_Pincode,
} from "../../../../Apis/deliveryPincodeApi/DeliveryPincodeApi";

const DeliveryPincodeDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(DeliveryPincodeContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get delivery pincode Details
   */
  const {
    mutate: PincodeMasterDetails,
    isLoading: isLoadingPincodeMasterDetails,
  } = useMutation((deliveryPincodeId) =>
    Get_Details_Delivery_Pincode(deliveryPincodeId, setFormData, setdialogs)
  );

  const GetPincodeMasterDetails = (deliveryPincodeId) => {
    PincodeMasterDetails(deliveryPincodeId);
    setId(deliveryPincodeId);
    setIsEdit(false);
  };

  /**
   * delete delivery pincode
   */
  const { mutate: PincodeMasterDelete, isLoading: isLoadingDelete } =
    useMutation((deliveryPincodeId) =>
      Delete_Delivery_Pincode(deliveryPincodeId, refetch)
    );

  const DeleteDeliveryOffDay = async (deliveryPincodeId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      PincodeMasterDelete(deliveryPincodeId);
    }
  };

  /**
   * enable / disable delivery pincode
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Delivery_Pincode(formData, refetch)
  );

  const handleDeliveryPincode = (deliveryPincodeId, status) => {
    let formDate = {
      deliveryPincodeId: deliveryPincodeId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.deliveryPincodeId === deliveryPincodeId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 200,
      field: "pincode",
      headerName: "Pincode",
      renderCell: (params) =>
        params.row.pincodeDetail ? params.row.pincodeDetail?.pincode : "--",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "store",
      headerName: "Store",
      renderCell: (params) =>
        params.row.storeDetail ? params.row.storeDetail?.name : "--",
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "isDeliveryAvailable",
      headerName: "Delivery Available",
      renderCell: (params) =>
        params.row.isDeliveryAvailable ? (
          <p
            style={{
              color: "green",
              fontWeight: "900",
              letterSpacing: "0.5px",
            }}
            className="m-0"
          >
            Allow
          </p>
        ) : (
          <p
            style={{ color: "red", fontWeight: "900", letterSpacing: "0.5px" }}
            className="m-0"
          >
            Danny
          </p>
        ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleDeliveryPincode(
                params.row.deliveryPincodeId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  GetPincodeMasterDetails(params.row.deliveryPincodeId)
                }
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() =>
                  DeleteDeliveryOffDay(params.row.deliveryPincodeId)
                }
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingPincodeMasterDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.deliveryPincodeId}
        addButton={true}
        search={false}
      />
    </Fragment>
  );
};

export default DeliveryPincodeDataTabelContainer;
