import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../constants";

const SecondPrivateRoute = () => {
  return localStorage.getItem("authToken") &&
    +localStorage.getItem("role") === Role.Store_Admin ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to="/" />
  );
};

export default SecondPrivateRoute;
