import React, { useContext } from "react";
import CssContext from "../../../context/css_context/CssContext/CssContext";

const MobileViewDiv = () => {
  const { mobileSidebar, setMobileSidebar, setMobileSidebarOpenAndClose } =
    useContext(CssContext);

  const handleCloseSidebar = () => {
    setMobileSidebar(false);
    setMobileSidebarOpenAndClose(false);
  };
  return (
    <div
      className={mobileSidebar ? "layout-mask modal-in" : ""}
      onClick={() => handleCloseSidebar()}
    ></div>
  );
};

export default MobileViewDiv;
