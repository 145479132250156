import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import AttributeContext from "../../../../context/AttributeContext/AttributeContext";
import {
  Delete_Attribute,
  Get_Details_Attribute,
} from "../../../../Apis/attributeApi/AttributeApi";

const AttributeDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(AttributeContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get attribute Details
   */
  const { mutate: attributeDetails, isLoading: isLoadingAttributeDetails } =
    useMutation((attributeId) =>
      Get_Details_Attribute(attributeId, setFormData, setdialogs)
    );

  const GetAttributeDetails = (attributeId) => {
    attributeDetails(attributeId);
    setId(attributeId);
    setIsEdit(false);
  };

  /**
   * delete attribute
   */
  const { mutate: attributeDelete, isLoading: isLoadingDelete } = useMutation(
    (attributeId) => Delete_Attribute(attributeId, refetch)
  );

  const DeleteAttribute = async (attributeId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      attributeDelete(attributeId);
    }
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "value",
      headerName: "Value",
      renderCell: (params) => (params.row.value ? params.row.value : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "attributeType",
      headerName: "Attribute Type",
      renderCell: (params) =>
        params.row.attributeType
          ? params.row.attributeType === 1
            ? "Box"
            : params.row.attributeType === 2
            ? "Drop Down"
            : params.row.attributeType === 3
            ? "Image"
            : "--"
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetAttributeDetails(params.row.attributeId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteAttribute(params.row.attributeId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingAttributeDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.attributeId}
        addButton={true}
      />
    </Fragment>
  );
};

export default AttributeDataTabelContainer;
