import React from "react";

const Breadcrumb = (props) => {
  const { name, slace, name1 } = props;
  return (
    <div className="topbar-breadcrumb">
      <nav
        className="layout-breadcrumb"
        style={{ display: "flex", justifyContent: "end" }}
      >
        <ol style={{ display: "flex" }}>
          <li style={{ textTransform: "capitalize" }}>{name}</li>
          {slace && (
            <>
              {" "}
              <li className="layout-breadcrumb-chevron"> {slace} </li>
              <li>{name1}</li>
            </>
          )}
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;
