import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  PRODUCT,
  PRODUCT_TAG,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import ProductTagMasterContext from "../../../../context/ProductTagMasterContext/ProductTagMasterContext";
import { get_Product_Tag_Master } from "../../../../Apis/productTagMasterApi/ProductTagMasterApi";
import ProductTagMasterDataTabelContainer from "./ProductTagMasterDataTabelContainer";
import { BackButton } from "../../../../reuseableComponents/buttons";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const ProductTagMaster = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
  } = useContext(ProductTagMasterContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const history = useNavigate();

  /**
   * get product tag master List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { tagName: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getProductTagMaster", paramsObj],
    async () => await get_Product_Tag_Master(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };

  return (
    <>
      <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_TAG} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <ProductTagMasterDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default ProductTagMaster;
