import axios from "axios";
import {
  CREATE_ATTRIBUTE_MASTER,
  DELETE_ATTRIBUTE_MASTER,
  EDIT_ATTRIBUTE_MASTER,
  ENABLE_DISABLE_ATTRIBUTE_MASTER,
  GET_ATTRIBUTE_MASTER,
  GET_ATTRIBUTE_MASTER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { ATTRIBUTE_TYPE_DROP } from "../../constants";

/**
 * get attribute master list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Attribute_Master = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ATTRIBUTE_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add attribute master api call
 * @param {form data} formData
 * @returns
 */

export const Create_Attribute_Master = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_ATTRIBUTE_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable attribute master api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Attribute_Master = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_ATTRIBUTE_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details attribute master
 * @param {*} attributeMasterId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Attribute_Master = (
  attributeMasterId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ATTRIBUTE_MASTER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { attributeMasterId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      let newArr = ATTRIBUTE_TYPE_DROP;
      setFormData({
        name: data?.name || "",
        attributeType:
          newArr.find((elem) => elem.code === data?.attributeType) || null,
        order: data?.order || "",
      });
      setdialogs(true);
    });
};

/**
 * update attribute master api call
 * @param {form data} formData
 * @param {*} attributeMasterId
 * @returns
 */

export const Put_Update_Attribute_Master = (formData, attributeMasterId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_ATTRIBUTE_MASTER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { attributeMasterId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete attribute master api call
 * @param {form data} formData
 * @param {*} attributeMasterId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Attribute_Master = (attributeMasterId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_ATTRIBUTE_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: { attributeMasterId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
