import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useContext } from "react";
import { Col, Form, Row } from "reactstrap";
import NotificationContext from "../../../../context/NotificationContext/NotificationContext";
import CropImagePopup from "../../../CropImagePopup";
import { FileType, TYPE_FELDS } from "../../../../constants";
import {
  FormDataFeild,
  FormDataFeildTextArea,
} from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { Upload_Image } from "../../../../Apis/userApi/UserApi";

const NotificationModal = ({ addNotification, id, emptyField }) => {
  const {
    notificationDialog,
    formData,
    setFormData,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    dialogModal,
    setdialogsModal,
  } = useContext(NotificationContext);

  const footerContent = (
    <div>
      <Button
        label="No"
        icon="pi pi-times"
        onClick={() => emptyField()}
        className="p-button-text"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        onClick={() => addNotification(id)}
        autoFocus
      />
    </div>
  );
  const openImageUploadModal = (e) => {
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: reader.result,
        };
      });
    };
    setdialogsModal(true);
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const { mutate: uploadImage } = useMutation(
    (file) =>
      Upload_Image(file, setdialogsModal, setFormData, FileType.NOTIFICATION),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          let imageUrl =
            response.data.payload.baseUrl + response.data.payload.imageUrl;
          setFormData((prev) => {
            return {
              ...prev,
              imageUrl,
            };
          });
        }
      },
    }
  );
  return (
    <Dialog
      header="Notification"
      visible={notificationDialog}
      style={{ width: "50vw" }}
      onHide={() => emptyField()}
      footer={footerContent}
    >
      <Form>
        <Row>
          <div className="field mb-4 col-12 md:col-12">
            <div
              style={{
                marginBottom: "15px",
              }}
              className="d-flex justify-content-center flex-wrap "
            >
              <div
                style={{
                  height: "150px",
                  width: "185px",
                }}
                className="d-flex justify-content-center "
              >
                <img
                  src={formData?.imageUrl}
                  alt=""
                  style={{
                    width: "80%",
                    height: "98%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center flex-wrap ">
              <input
                className="custom-file-input"
                type="file"
                onChange={(e) => openImageUploadModal(e)}
              />
              <Button
                className="p-button p-component p-button-outlined w-full sm:w-auto flex-order-0 sm:flex-order-1"
                style={{ height: "40px" }}
              >
                <span
                  className={`p-button-icon p-c p-button-icon-left pi pi-camera `}
                ></span>
                <span
                  className="p-button-label p-c"
                  style={{ fontWeight: "100" }}
                >
                  {"Choose"}
                </span>
              </Button>
            </div>
          </div>
          <Col md="12 mb-4">
            <FormDataFeild
              label={"Title"}
              name="title"
              id="title"
              type={TYPE_FELDS.text}
              placeholder={"Title"}
              value={formData.title}
              handleChange={handleChangeInput}
            />
          </Col>
          <Col md="12 mb-4">
            <FormDataFeildTextArea
              label={"Description"}
              name="template"
              id="template"
              type={TYPE_FELDS.text}
              placeholder={"Description"}
              value={formData.template}
              handleChange={handleChangeInput}
            />
          </Col>
        </Row>
      </Form>
      <CropImagePopup
        dialogModal={dialogModal}
        setdialogsModal={setdialogsModal}
        image={formData?.image}
        aspect={aspect}
        crop={crop}
        setCrop={setCrop}
        completedCrop={completedCrop}
        setCompletedCrop={setCompletedCrop}
        scale={scale}
        rotate={rotate}
        setFormData={setFormData}
        uploadImage={uploadImage}
      />
    </Dialog>
  );
};

export default NotificationModal;
