import React, { useState } from "react";
import NotificationContext from "./NotificationContext";

const NotificationState = (props) => {
  const [notificationDialog, setNotificationDialogs] = useState(false);
  const [formData, setFormData] = useState({
    image: "",
    imageUrl: "",
    title: "",
    template: "",
  });

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [dialogModal, setdialogsModal] = useState(false);
  return (
    <NotificationContext.Provider
      value={{
        notificationDialog,
        setNotificationDialogs,
        formData,
        setFormData,
        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        imageType,
        setImageType,
        dialogModal,
        setdialogsModal,
      }}
    >
      {props.children}
    </NotificationContext.Provider>
  );
};

export default NotificationState;
