import React, { useContext } from "react";
import { ClockLoader } from "react-spinners";
import LoaderContext from "../context/LoaderContext/LoaderContext";

const Clock_Loader = () => {
  const { showLoader } = useContext(LoaderContext);
  const style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    zIndex: "999",
    width: "100%",
    /* background-color: rgb(177 177 177 / 65%); */
    backgroundColor: "black",
    // backgroundColor: "#b293bf75",
  };
  return (
    showLoader && (
      <div className="loader" style={style}>
        {" "}
        <ClockLoader
          // color={"#883ea6"}
          color={"#FFD700"}
          loading={true}
          size={100}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    )
  );
};

export default Clock_Loader;
