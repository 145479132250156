import axios from "axios";
import {
  CREATE_DETAILS_PAGE,
  DELETE_DETAILS_PAGE,
  EDIT_DETAILS_PAGE,
  ENABLE_DISABLE_DETAILS_PAGE,
  GET_DETAILS_PAGE,
  GET_DETAILS_PAGE_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get details page list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Details_Page = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DETAILS_PAGE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add details page api call
 * @param {form data} formData
 * @param {store list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyField} emptyField
 * @returns
 */

export const Create_Details_Page = (
  formData,
  refetch,
  setdialogs,
  emptyField
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_DETAILS_PAGE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyField();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable store api call
 * @param {form data as isActive & pageId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Details_Page = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_DETAILS_PAGE}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { pageId: formData.pageId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details for details page
 * @param {*} pageId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Details_Page = (pageId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DETAILS_PAGE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { pageId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      setFormData({
        title: data?.title || "",
        pageUrl: data?.pageUrl || "",
        seoTitle: data?.seoTitle || "",
        seoKeyword: data?.seoKeyword || "",
        seoDesc: data?.seoDesc || "",
        description: data?.description || "",
        hyperLink: data?.hyperLink || "",
        detailsMasterId: data?.detailsMasterId
          ? { code: data?.detailsMasterId, name: data?.detailsMaster?.title }
          : null,
        order: data?.order || "",
      });
      setdialogs(true);
    });
};

/**
 * update details page api call
 * @param {form data} formData
 * @param {*} pageId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyField} emptyField
 * @returns
 */

export const Put_Update_Details_Page = (
  formData,
  pageId,
  refetch,
  setdialogs,
  emptyField
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_DETAILS_PAGE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { pageId },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyField();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete details page api call
 * @param {form data} formData
 * @param {*} pageId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Details_Page = (pageId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_DETAILS_PAGE}`, {
      headers: { Authorization: jwt_token },
      params: { pageId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
