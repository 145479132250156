import axios from "axios";
import {
  DELETE_INQUIRY,
  ENABLE_DISABLE_INQUIRY,
  GET_INQUIRY,
  GET_INQUIRY_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get inquiry list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Inquiry = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_INQUIRY}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 *enable - disable inquiry api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Inquiry = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_INQUIRY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details inquiry
 * @param {*} inquiryId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Inquiry = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_INQUIRY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then((res) => {
      return res.data.payload.data;
    });
};

/**
 * delete inquiry api call
 * @param {form data} formData
 * @param {*} inquiryId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Inquiry = (inquiryId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_INQUIRY}`, {
      headers: { Authorization: jwt_token },
      params: { inquiryId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
