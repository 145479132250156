import axios from "axios";
import {
  CREATE_ADMIN_USER,
  DELETE_ADMIN_USER,
  EDIT_ADMIN_USER,
  ENABLE_DISABLE_ADMIN_USER,
  FILE,
  GET_ADMIN_DASHBOARD,
  GET_ADMIN_USER,
  GET_ADMIN_USER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { RoleDrop, optionsDialCode } from "../../constants";

/**
 * get User list data
 * @param {paganization params} paramsObj
 * @returns data response
 */

export const get_User = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ADMIN_USER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get dashboard data
 * @returns data response
 */

export const get_Dashboard = () => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ADMIN_DASHBOARD}`, {
      headers: { Authorization: jwt_token },
    })
    .then((res) => res.data);
};

/**
 * add user api call
 * @param {form data} formData
 * @returns
 */

export const Create_User = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_ADMIN_USER}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable user api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_User = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_ADMIN_USER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

export const Get_Details_User = (userId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ADMIN_USER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { userId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let [dialCode] = optionsDialCode.filter(
        (elem) => elem.code === data.dialCode
      );
      let [roleId] = RoleDrop.filter((elem) => elem.code === data.roleId);
      setFormData({
        name: data ? data?.name : null,
        phone: data ? data?.phone : null,
        dialCode: data ? dialCode : null,
        roleId: data ? roleId : null,
        assignStores:
          data && data?.assignStores?.length
            ? data.assignStores.map((elem) => {
                return {
                  code: elem?.storeId,
                  name: elem?.storeDetail?.name,
                };
              })
            : null,
        email: data ? data?.email : null,
        image: data ? data?.image : null,
      });
      setdialogs(true);
    });
};

/**
 * update user api call
 * @param {form data} formData
 * @param {*} userId
 * @returns
 */

export const Put_Update_User = (formData, userId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_ADMIN_USER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { userId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete user api call
 * @param {form data} formData
 * @param {*} userId
 * @param {list api call } refetch
 * @returns
 */

export const DELETE_USER = (userId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_ADMIN_USER}`, {
      headers: { Authorization: jwt_token },
      params: { userId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Upload_Image = (file, setdialogsModal, setFormData, type) => {
  const jwt_token = localStorage.getItem("authToken");
  let formData = new FormData();
  formData.append("type", type);
  formData.append("file", file);
  return axios
    .post(`${process.env.REACT_APP_API_URL}${FILE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      setFormData((prev) => {
        return {
          ...prev,
          image: message.data.payload.baseUrl + message.data.payload.imageUrl,
        };
      });
      ToastMessage(message.status, message.data.message);
      setdialogsModal(false);
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Upload_Image_Order_Setting = (
  file,
  setImageDialogsModal,
  setFormData,
  type
) => {
  const jwt_token = localStorage.getItem("authToken");
  let formData = new FormData();
  formData.append("type", type);
  formData.append("file", file);
  return axios
    .post(`${process.env.REACT_APP_API_URL}${FILE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      setFormData((prev) => {
        return {
          ...prev,
          companyLogo:
            message.data.payload.baseUrl + message.data.payload.imageUrl,
        };
      });
      ToastMessage(message.status, message.data.message);
      setImageDialogsModal(false);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Get_Details__User = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ADMIN_USER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then(async (res) => {
      return res.data.payload.data;
    });
};
