import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { attributeImageUploadClose } from "../../../../redux/slices/selectAttributePopup";
import ProductImageUpload from "./ProductImageUpload";
import { Col } from "reactstrap";
import { TYPE_FELDS } from "../../../../constants";
import { Button } from "primereact/button";
import { Row } from "react-bootstrap";

const AttributeImageUploadPopup = ({
  openImageUploadModal,
  isEdit,
  handleRemove,
  handleChange,
  handleAddMore,
  attributeImages,
  setAttributeImages,
  attributeMasterIndex,
  attributeMasterId,
}) => {
  const open = useSelector((state) => state.select_attribute_popup);
  const dispatch = useDispatch();
  return (
    <Dialog
      header="Attribute"
      visible={open.attributeImageUpload}
      onHide={() => dispatch(attributeImageUploadClose())}
      draggable={false}
      resizable={false}
      className="custom-device"
    >
      <Row>
        {attributeImages[attributeMasterIndex][attributeMasterId]?.map(
          (imageElem, index) => {
            return (
              <Col md="3 mb-3" key={index}>
                <ProductImageUpload
                  onChange={openImageUploadModal}
                  index={index}
                  image={imageElem?.imagePath}
                  imgAltText={imageElem.imgAltText}
                  isEdit={isEdit}
                  length={
                    attributeImages[attributeMasterIndex][attributeMasterId]
                      .length
                  }
                  id={imageElem.id}
                  handleRemove={handleRemove}
                  handleChange={handleChange}
                  imgAltTextShow={false}
                  images={true}
                  attributeMasterId={attributeMasterId}
                  attributeMasterIndex={attributeMasterIndex}
                />
              </Col>
            );
          }
        )}
      </Row>
      <div className="btn-style gap-3 justify-content-center pb-5">
        <Button
          label="Add More"
          icon="pi pi-plus"
          severity="warning"
          onClick={() =>
            handleAddMore({
              isCondition: true,
              isImages: true,
              attributeMasterId: attributeMasterId,
            })
          }
          type={TYPE_FELDS.button}
        />
      </div>
    </Dialog>
  );
};

export default AttributeImageUploadPopup;
