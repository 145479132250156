import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./navbar";

const MainHeader = (props) => {
  const { windowSize, setMainDivSidebar, maindivSidebar } = props;
  return (
    <>
      <Navbar
        windowSize={windowSize}
        setMainDivSidebar={setMainDivSidebar}
        maindivSidebar={maindivSidebar}
      />
      <Outlet />
    </>
  );
};

export default MainHeader;
