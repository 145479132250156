import React, { useState } from "react";
import RoomFacilityContext from "./RoomFacilityContext";

const RoomFacilityState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**organization edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [facilitiesId, setFacilitiesId] = useState(null);
  const [formData, setFormData] = useState({
    guestHouseId: null,
    guestId: null,
    categoryId: null,
    roomId: null,
    checkIn: null,
    checkOut: null,
    member: null,
    totalAmount: null,
    refundAmount: null,
    checkIn_CheckOut: null,
  });
  return (
    <RoomFacilityContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        facilitiesId,
        setFacilitiesId,
        formData,
        setFormData,
      }}
    >
      {props.children}
    </RoomFacilityContext.Provider>
  );
};

export default RoomFacilityState;
