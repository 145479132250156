import React, { Fragment, useContext } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  AttributeName,
  ORDER,
  ATTRIBUTE_TYPE,
  ATTRIBUTE_TYPE_DROP,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createAttributeMasterSchema } from "../../../../utils/validationSchema";
import AttributeMasterContext from "../../../../context/AttributeMasterContext/AttributeMasterContext";
import {
  Create_Attribute_Master,
  Put_Update_Attribute_Master,
} from "../../../../Apis/attributeMasterApi/AttributeMasterApi";

const AttributeMasterCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(AttributeMasterContext);
  const { refetch } = props;

  /**
   *
   * @param {event } e
   * create attribute master & update attribute master
   */

  const {
    mutate: createAttributeMaster,
    isLoading: createAttributeMasterLoader,
  } = useMutation((formData) => Create_Attribute_Master(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setdialogs(false);
        setFormData({ name: "", attributeType: null, order: "" });
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const { mutate: updateAttributeMaster, isLoading: updateBrandMasterLoader } =
    useMutation((formData) => Put_Update_Attribute_Master(formData, id), {
      onSuccess: (response) => {
        refetch();
        setdialogs(false);
        setFormData({ name: "", attributeType: null, order: "" });
        setErrors({});
        ToastMessage(response.status, response.data.message);
      },
    });

  const handleAttributeCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createAttributeMasterSchema();
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          attributeType: formData?.attributeType
            ? formData?.attributeType?.code
            : null,
        };
        updateAttributeMaster(newData);
      } else {
        let newData = {
          ...formData,
          attributeType: formData?.attributeType
            ? formData?.attributeType?.code
            : null,
        };
        createAttributeMaster(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);

      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeAttributeMaster = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={createAttributeMasterLoader || updateBrandMasterLoader}
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleAttributeCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={AttributeName}
                      name="name"
                      id="name"
                      type={TYPE_FELDS.text}
                      placeholder={AttributeName}
                      value={formData.name}
                      handleChange={handleChangeAttributeMaster}
                    />
                    {errors.name && (
                      <span className="error-validation">{errors.name}</span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={ATTRIBUTE_TYPE}
                      name="attributeType"
                      id="attributeType"
                      placeholder={ATTRIBUTE_TYPE}
                      value={formData.attributeType}
                      handleChange={handleChangeAttributeMaster}
                      dropdown={true}
                      option={ATTRIBUTE_TYPE_DROP}
                      filter={false}
                    />
                    {errors.attributeType && (
                      <span className="error-validation">
                        {errors.attributeType}
                      </span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={ORDER}
                      name="order"
                      id="order"
                      type={TYPE_FELDS.number}
                      placeholder={ORDER}
                      value={formData.order}
                      handleChange={handleChangeAttributeMaster}
                    />
                    {errors.order && (
                      <span className="error-validation">{errors.order}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createAttributeMasterLoader || updateBrandMasterLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default AttributeMasterCreateFormPopup;
