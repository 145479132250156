import React, { useState } from "react";
import CategoryContext from "./CategoryContext";

const CategoryState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");
  const [pCatId, setpCatId] = useState("");
  const [pagination, setPagination] = useState({
    first: 0,
    page: 0,
    pageCount: 10,
  });

  /**brand master edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [id, setId] = useState(null);
  const [catId, setCatId] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    pCatId: "",
    desc: "",
    image: "",
    slugUrl: "",
    seoTitle: "",
    seoDesc: "",
    seoKeyword: "",
    schemaTag: "1",
    order: "",
    isHome: false,
    homeOrder: 1,
  });
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedParentCategory, setSelectedParentCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [dialogModal, setdialogsModal] = useState(false);

  return (
    <CategoryContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        id,
        setId,
        formData,
        setFormData,
        yes,
        setYes,
        errors,
        setErrors,

        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        imageType,
        setImageType,
        dialogModal,
        setdialogsModal,
        pCatId,
        setpCatId,
        selectedParentCategory,
        setSelectedParentCategory,
        selectedSubCategory,
        setSelectedSubCategory,
        pagination,
        setPagination,
        catId,
        setCatId,
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryState;
