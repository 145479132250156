import { Dialog } from "primereact/dialog";
import React from "react";

const Modal = (props) => {
  const { hide, header, visible, Component } = props;
  return (
    <Dialog
      header={header}
      className="modal_style"
      visible={visible}
      position="top"
      onHide={() => hide(false)}
      draggable={false}
      resizable={false}
    >
      {Component}
    </Dialog>
  );
};

export default Modal;
