import React, { useState } from "react";
import GuestMasterContext from "./GuestMasterContext";

const GuestMasterState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");
  const [cityFilter, setCityFilter] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [countryFilter, setCountryFilter] = useState("");
  const [dialCodeFilter, setDialCodeFilter] = useState("");
  const [guestFilter, setGuestFilter] = useState("");

  /**organization edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [webcamDialog, setWebCamDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [guestId, setGuestId] = useState(null);
  const [capturedImage, setCapturedImage] = useState(null);
  const [guestImageShow, setGuestImageShow] = useState("");
  const [idProofImageShow, setIdProofImageShow] = useState("");
  const [formData, setFormData] = useState({
    fname: null,
    lname: null,
    mname: null,
    gender: null,
    dialCode: null,
    contactNumber: null,
    email: null,
    dob: null,
    age: null,
    village: null,
    address: null,
    countryId: null,
    stateId: null,
    cityId: null,
    pinCode: null,
    idProof: null,
    idProofNumber: null,
    image: null,
    refGuestId: null,
  });

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(4 / 6);
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");

  return (
    <GuestMasterContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        guestId,
        setGuestId,
        formData,
        setFormData,
        cityFilter,
        setCityFilter,
        dialCodeFilter,
        setDialCodeFilter,
        webcamDialog,
        setWebCamDialog,

        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        capturedImage,
        setCapturedImage,
        stateFilter,
        setStateFilter,
        countryFilter,
        setCountryFilter,
        imageType,
        setImageType,
        guestImageShow,
        setGuestImageShow,
        idProofImageShow,
        setIdProofImageShow,
        guestFilter,
        setGuestFilter,
      }}
    >
      {props.children}
    </GuestMasterContext.Provider>
  );
};

export default GuestMasterState;
