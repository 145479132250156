import axios from "axios";
import {
  CREATE_DELIVERY_OFF_DAY,
  DELETE_DELIVERY_OFF_DAY,
  EDIT_DELIVERY_OFF_DAY,
  ENABLE_DISABLE_DELIVERY_OFF_DAY,
  GET_DELIVERY_OFF_DAY,
  GET_DELIVERY_OFF_DAY_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { SLOT_OFF_TIME } from "../../constants";

/**
 * get delivery off day list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Delivery_Off_Day = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DELIVERY_OFF_DAY}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add delivery off day api call
 * @param {form data} formData
 * @returns
 */

export const Create_Delivery_Off_Day = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_DELIVERY_OFF_DAY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable delivery off day api call
 * @param {form data as isActive & dayOffId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Delivery_Off_Day = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_DELIVERY_OFF_DAY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { dayOffId: formData.dayOffId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details delivery off day
 * @param {*} dayOffId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Delivery_Off_Day = (
  dayOffId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DELIVERY_OFF_DAY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { dayOffId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      setFormData({
        storeId: data?.storeId
          ? { code: data?.storeId, name: data?.storeDetail?.name }
          : null,
        offDate: data?.offDate ? new Date(data?.offDate) : "",
        slotOffType: data?.slotOffType
          ? data?.slotOffType === SLOT_OFF_TIME.ALL
            ? { code: SLOT_OFF_TIME.ALL, name: "ALL" }
            : { code: SLOT_OFF_TIME.SPECIFIC, name: "SPECIFIC" }
          : null,
        slotId: data?.slotId
          ? { code: data?.slotId, name: data?.timeSlotDetail?.slotName }
          : null,
      });
      setdialogs(true);
    });
};

/**
 * update delivery off day api call
 * @param {form data} formData
 * @param {*} dayOffId
 * @returns
 */

export const Put_Update_Delivery_Off_Day = (formData, dayOffId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_DELIVERY_OFF_DAY}`, formData, {
      headers: { Authorization: jwt_token },
      params: { dayOffId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete delivery off day api call
 * @param {form data} formData
 * @param {*} dayOffId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Delivery_Off_Day = (dayOffId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_DELIVERY_OFF_DAY}`, {
      headers: { Authorization: jwt_token },
      params: { dayOffId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
