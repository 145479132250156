import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import {
  IsLoadingResponse,
  getLocalStringTime,
} from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import {
  Delete_Delivery_Slot,
  Enable_Disable_Delivery_Slot,
  Get_Details_Delivery_Slot,
} from "../../../../Apis/deliverySlotApi/DeliverySlotApi";
import DeliverySlotContext from "../../../../context/DeliverySlotContext/DeliverySlotContext";

const DeliverySlotDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(DeliverySlotContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get delivery slot Details
   */
  const {
    mutate: deliverySlotDetails,
    isLoading: isLoadingDeliverySlotDetails,
  } = useMutation((slotId) =>
    Get_Details_Delivery_Slot(slotId, setFormData, setdialogs)
  );

  const GetDeliverySlotDetails = (slotId) => {
    deliverySlotDetails(slotId);
    setId(slotId);
    setIsEdit(false);
  };

  /**
   * delete delivery slot
   */
  const { mutate: deliverySlotDelete, isLoading: isLoadingDelete } =
    useMutation((slotId) => Delete_Delivery_Slot(slotId, refetch));

  const DeleteDeliverySlot = async (slotId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      deliverySlotDelete(slotId);
    }
  };

  /**
   * enable / disable delivery slot
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Delivery_Slot(formData, refetch)
  );

  const handleDeliverySlotStatusChange = (slotId, status) => {
    let formDate = {
      slotId: slotId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.slotId === slotId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "slotName",
      headerName: "Slot Name",
      renderCell: (params) =>
        params.row.slotName ? params.row.slotName : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "startTime",
      headerName: "Start Time",
      renderCell: (params) => {
        return params.row.startTime
          ? moment(getLocalStringTime(params.row.startTime)).format("HH:mm")
          : "--";
      },
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "endTime",
      headerName: "End Time",
      renderCell: (params) =>
        params.row.endTime
          ? moment(getLocalStringTime(params.row.endTime)).format("HH:mm")
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "storeName",
      headerName: "Store Name",
      renderCell: (params) =>
        params.row.storeDetail ? params.row.storeDetail.name : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleDeliverySlotStatusChange(
                params.row.slotId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetDeliverySlotDetails(params.row.slotId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteDeliverySlot(params.row.slotId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingDeliverySlotDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.slotId}
        addButton={true}
      />
    </Fragment>
  );
};

export default DeliverySlotDataTabelContainer;
