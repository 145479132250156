import axios from "axios";
import {
  CHECK_SLUG_ORDER_SETTING,
  CREATE_ORDER_SETTING,
  DELETE_ORDER_SETTING,
  EDIT_ORDER_SETTING,
  ENABLE_DISABLE_ORDER_SETTING,
  GET_ORDER_SETTING,
  GET_ORDER_SETTING_DETAILS,
  GET_ORDER_SETTING_FOR_ADD_UPDATE,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { TYPE } from "../../constants";

/**
 * get order setting list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Order_Setting = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORDER_SETTING}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get order setting list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Order_Setting_For_Add_Update = (paramsObj, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}${GET_ORDER_SETTING_FOR_ADD_UPDATE}`,
      {
        headers: { Authorization: jwt_token },
        params: paramsObj,
      }
    )
    .then((res) => {
      let [valueOfType] = TYPE.filter((elem) => elem.code === elem.type);
      setFormData((prev) => {
        return {
          ...prev,
          stocks: res.data?.payload?.data?.map((elem) => {
            return {
              ...prev.stocks,
              globalSettingId: elem.globalSettingId,
              storeName: elem.name,
              qty: 0,
              minStock: elem?.productStocks[0]?.minStock,
              maxStock: elem?.productStocks[0]?.maxStock,
              type: valueOfType ?? null,
              totalQty: elem?.productStocks[0]?.qty ?? 0,
              note: elem?.productStocks[0]?.note ?? null,
              copyTotalQty: elem?.productStocks[0]?.qty ?? 0,
            };
          }),
        };
      });
      return res.data;
    });
};

/**
 * add order setting api call
 * @param {form data} formData
 * @param {order setting list function} refetch
 * @param {modal close} setdialogs
 * @param {setFormData} setFormData
 * @returns
 */

export const Create_Order_Setting = (
  formData,
  refetch,
  setdialogs,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_ORDER_SETTING}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable order setting api call
 * @param {form data as isActive & globalSettingId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Order_Setting = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_ORDER_SETTING}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { globalSettingId: formData.globalSettingId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details order setting
 * @param {*} globalSettingId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Order_Setting = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ORDER_SETTING_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: paramObj,
    })
    .then((res) => {
      let data = res.data.payload.data;
      return data;
    });
};

/**
 * update order setting api call
 * @param {form data} formData
 * @param {*} globalSettingId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Put_Update_Order_Setting = (
  formData,
  globalSettingId,
  refetch,
  setdialogs,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_ORDER_SETTING}`, formData, {
      headers: { Authorization: jwt_token },
      params: { globalSettingId },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete order setting api call
 * @param {form data} formData
 * @param {*} globalSettingId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Order_Setting = (globalSettingId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_ORDER_SETTING}`, {
      headers: { Authorization: jwt_token },
      params: { globalSettingId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug order setting api call
 * @param {form data} formData
 * @param {*} globalSettingId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_ORDER_SETTING}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {
      // if (message.status === 200) {
      //   ToastMessage(message.status, message.data.message);
      // }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
