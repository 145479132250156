import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  ATTRIBUTE_MASTER,
  Category,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createCategoryAttributeMapSchema } from "../../../../utils/validationSchema";
import { get_Attribute_Master } from "../../../../Apis/attributeMasterApi/AttributeMasterApi";
import {
  Create_Category_Attribute_Map,
  Put_Update_Category_Attribute_Map,
} from "../../../../Apis/categoryAttributeMapApi/CategoryAttributeMapApi";
import CategoryAttributeMapContext from "../../../../context/CategoryAttributeMapContext/CategoryAttributeMapContext";
import { get_Category_Master } from "../../../../Apis/categoryApi/CategoryApi";

const CategoryAttributeMapCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(CategoryAttributeMapContext);
  const { refetch } = props;
  const [filterDrop, setFilterDrop] = useState({
    attributeMasterFilter: "",
    categoryFilter: "",
  });

  /**
   *
   * @param {event } e
   * create category attribute map & update category attribute map
   */

  const {
    mutate: createCategoryAttributeMap,
    isLoading: createCategoryAttributeMapLoader,
  } = useMutation((formData) => Create_Category_Attribute_Map(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setdialogs(false);
        setFormData({
          attributeMasterId: null,
          catId: null,
        });
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const {
    mutate: updateCategoryAttributeMap,
    isLoading: updateCategoryAttributeMapLoader,
  } = useMutation(
    (formData) => Put_Update_Category_Attribute_Map(formData, id),
    {
      onSuccess: (response) => {
        refetch();
        setdialogs(false);
        setFormData({
          attributeMasterId: null,
          catId: null,
        });
        setErrors({});
        ToastMessage(response.status, response.data.message);
      },
    }
  );

  const handleCategoryAttributeMapCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createCategoryAttributeMapSchema();
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          attributeMasterId: formData?.attributeMasterId
            ? formData?.attributeMasterId?.code
            : null,
          catId: formData?.catId ? formData?.catId?.code : null,
        };
        updateCategoryAttributeMap(newData);
      } else {
        let newData = {
          ...formData,
          attributeMasterId: formData?.attributeMasterId
            ? formData?.attributeMasterId?.code
            : null,
          catId: formData?.catId ? formData?.catId?.code : null,
        };
        createCategoryAttributeMap(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);

      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeCategoryAttributeMapMaster = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  let parmaObj = {
    showAll: true,
    search: { name: filterDrop.attributeMasterFilter },
  };

  let { data } = useQuery(
    ["getAttributeMasterDrop1", parmaObj],
    async () => await get_Attribute_Master(parmaObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  let paramsCategoryObj = {
    showAll: true,
    search: { brandName: filterDrop.categoryFilter },
    allAsParent: true,
  };
  let { data: categoryMasterDrop } = useQuery(
    ["getCategoryMasterDrop", paramsCategoryObj],
    async () => await get_Category_Master(paramsCategoryObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          createCategoryAttributeMapLoader || updateCategoryAttributeMapLoader
        }
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleCategoryAttributeMapCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={ATTRIBUTE_MASTER}
                      name="attributeMasterId"
                      id="attributeMasterId"
                      placeholder={ATTRIBUTE_MASTER}
                      value={formData.attributeMasterId}
                      handleChange={handleChangeCategoryAttributeMapMaster}
                      dropdown={true}
                      option={data?.payload?.data?.map((elem) => {
                        return {
                          name: elem?.name,
                          code: elem?.attributeMasterId,
                        };
                      })}
                      search={(e) => {
                        setFilterDrop((prev) => {
                          return {
                            ...prev,
                            attributeMasterFilter: e.target.value,
                          };
                        });
                      }}
                    />
                    {errors.attributeMasterId && (
                      <span className="error-validation">
                        {errors.attributeMasterId}
                      </span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={`${Category} *`}
                      name="catId"
                      id="catId"
                      placeholder={`${Category} *`}
                      value={formData.catId}
                      handleChange={(e) => {
                        handleChangeCategoryAttributeMapMaster(e);
                      }}
                      dropdown={true}
                      option={categoryMasterDrop?.payload?.data?.map((elem) => {
                        return {
                          code: elem.catId,
                          name: elem.name,
                        };
                      })}
                      search={(e) =>
                        setFilterDrop((prev) => {
                          return {
                            ...prev,
                            categoryFilter: e.target.value,
                          };
                        })
                      }
                    />
                    {errors.catId && (
                      <span className="error-validation">{errors.catId}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createCategoryAttributeMapLoader ||
                        updateCategoryAttributeMapLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default CategoryAttributeMapCreateFormPopup;
