import React, { Fragment } from "react";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  USER,
  SLACE,
  USER_Detail,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { Get_Details__User } from "../../../../Apis/userApi/UserApi";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import moment from "moment";
import { BackButton } from "../../../../reuseableComponents/buttons";
import { REDIRECT_PATH } from "../../../../Routes";

const UserDetail = () => {
  const { userId } = useParams();
  const history = useNavigate();

  let paramObj = {
    userId,
  };
  let { data } = useQuery(
    ["getUser", paramObj],
    () => Get_Details__User(paramObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  return (
    <Fragment>
      <Breadcrumb name={USER} slace={SLACE} name1={USER_Detail} />
      <BackButton history={history} path={REDIRECT_PATH.USER} />
      <div className="tabel-contant">
        <div className={`tabelContent user-detail-tabel`}>
          <table className="m-5 ">
            <tbody>
              <tr>
                <td>Name</td>
                <td>:</td>
                <td>{data?.name}</td>
              </tr>
              <tr>
                <td>Email</td>
                <td>:</td>
                <td>{data?.email}</td>
              </tr>
              <tr>
                <td>Contact</td>
                <td>:</td>
                <td>{`${data?.dialCode} ${data?.phone}`}</td>
              </tr>
              <tr>
                <td>Last Login</td>
                <td>:</td>
                <td>
                  {moment(data?.lastLogin).isValid()
                    ? moment(data?.lastLogin).format("DD-MM-YYYY")
                    : "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default UserDetail;
