import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import React, { Fragment, useContext } from "react";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import ProductsStockContext from "../../../../context/ProductsStockContext/ProductsStockContext";
import {
  Delete_Products_Stock,
  Export_Excel_Products_Stock,
} from "../../../../Apis/productsStockApi/ProductTagMasterStockApi";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Button } from "primereact/button";

const ProductsStockDataTabelContainer = (props) => {
  const { setFilter, record } = useContext(ProductsStockContext);
  const { data, refetch, setPagination, pagination } = props;

  /**
   * delete products stock
   */
  const { mutate: productsStockDelete, isLoading: isLoadingDelete } =
    useMutation((stockId) => Delete_Products_Stock(stockId, refetch));

  const DeleteProductStock = async (stockId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      productsStockDelete(stockId);
    }
  };

  /**
   * enable / disable products stock
   */

  // const { mutate: statusChangeMutate } = useMutation((formData) =>
  //   Enable_Disable_Products_Stock(formData, refetch)
  // );

  // const handleProductsStockStatusChange = (stockId, status) => {
  //   let formDate = {
  //     stockId: stockId,
  //     isActive: status ? false : true,
  //   };

  //   data.payload.data = data.payload.data.map((row) => {
  //     if (row.stockId === stockId) {
  //       return {
  //         ...row,
  //         isActive: status ? false : true,
  //       };
  //     } else {
  //       return row;
  //     }
  //   });
  //   statusChangeMutate(formDate);
  // };

  /**
   * excel download products stock
   */

  const { mutate: downloadExcel } = useMutation((formData) =>
    Export_Excel_Products_Stock(formData)
  );

  const handleExport = () => {
    downloadExcel();
  };

  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingDelete} />

      <div className="tabel-contant">
        <div className={`tabelContent`}>
          <div className="tabelSearch">
            <div className="p-datatable-header">
              <div className="d-flex justify-content-end mb-3">
                <Button
                  label="Export"
                  severity="help"
                  icon="pi pi-file-export"
                  tooltip="Export"
                  tooltipOptions={{ position: "top" }}
                  onClick={() => handleExport()}
                />
              </div>
              <TreeTable
                value={data?.payload?.data.map((elem) => {
                  const sumQty = elem?.productStocks.reduce(
                    (acc, child) => acc + child?.qty,
                    0
                  );
                  return {
                    key: elem?.productId,
                    data: {
                      productName: elem?.productName,
                      qty: "Total Qty: " + sumQty,
                      minStock: "-",
                      maxStock: "-",
                      delete: "-",
                    },
                    children: elem?.productStocks?.map((child) => {
                      return {
                        key: child?.stockId,
                        data: {
                          productName: child?.storeDetail?.name,
                          qty: child?.qty,
                          minStock: child?.minStock,
                          maxStock: child?.maxStock,
                          delete: (
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() =>
                                  DeleteProductStock(child?.stockId)
                                }
                              >
                                <Delete style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          ),
                        },
                      };
                    }),
                  };
                })}
                onFilter={(e) => setFilter(e?.filters?.productName?.value)}
              >
                <Column
                  field="productName"
                  header="Product Name"
                  expander
                  filter
                  filterPlaceholder="Filter by product name"
                  filterField="productName"
                  filterMatchMode="contains"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="qty"
                  header="QTY"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="minStock"
                  header="Min Stock"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="maxStock"
                  header="Max Stock"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="delete"
                  header="Delete"
                  style={{ width: "250px" }}
                ></Column>
              </TreeTable>
              <Paginator
                first={pagination.first}
                rows={pagination.pageCount}
                totalRecords={record}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={(p) =>
                  setPagination({
                    first: p.first,
                    page: p.page,
                    pageCount: p.rows,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductsStockDataTabelContainer;
