import { Delete, Edit, Notifications } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { DISCOUNT_TYPE, TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import {
  Delete_Deal_Of_The_Day,
  Enable_Disable_Deal_Of_The_Day,
  Get_Details_Deal_Of_The_Day,
  Put_Notification_Send,
} from "../../../../Apis/dealOfTheDayApi/DealOfTheDayApi";
import DealOfTheDayContext from "../../../../context/DealOfTheDayContext/DealOfTheDayContext";
import NotificationContext from "../../../../context/NotificationContext/NotificationContext";
import NotificationModal from "../Products/NotificationModal";

const DealOfTheDayDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    setAspect,
    setFileType,
    dealId,
    setDealId,
  } = useContext(DealOfTheDayContext);
  const {
    setNotificationDialogs,
    formData: notificationFormData,
    setFormData: setNotificationFormData,
  } = useContext(NotificationContext);
  const { isLoading, data, refetch } = props;
  const history = useNavigate();

  /**
   * get deal of the day Details
   */
  const {
    mutate: dealOfTheDayDetails,
    isLoading: isLoadingDealOfTheDayDetails,
  } = useMutation((dealId) =>
    Get_Details_Deal_Of_The_Day(
      dealId,
      history,
      setFormData,
      setAspect,
      setFileType
    )
  );

  const getDealOfTheDay = (dealId) => {
    dealOfTheDayDetails(dealId);
    setId(dealId);
    setIsEdit(false);
  };

  /**
   * deal of the day notification
   */
  const { mutate: promoCodeNotification, isLoading: isLoadingNotification } =
    useMutation((formData) => Put_Notification_Send(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          emptyField();
        }
      },
    });

  const emptyField = () => {
    setDealId("");
    setNotificationFormData({
      image: "",
      imageUrl: "",
      title: "",
      template: "",
    });
    setNotificationDialogs(false);
  };

  const addDealOfTheDayNotification = (dealId) => {
    let formData = {
      dealId,
      ...notificationFormData,
    };
    const { image, ...newData } = formData;
    promoCodeNotification(newData);
  };

  /**
   * delete deal of the day
   */
  const { mutate: dealOfTheDayDelete, isLoading: isLoadingDelete } =
    useMutation((dealId) => Delete_Deal_Of_The_Day(dealId, refetch));

  const DeleteCategory = async (dealId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      dealOfTheDayDelete(dealId);
    }
  };

  /**
   * enable / disable deal of the day
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Deal_Of_The_Day(formData, refetch)
  );

  const handleCategoryStatusChange = (dealId, status) => {
    let formDate = {
      dealId: dealId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.dealId === dealId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.DEALOFTHEDAY}/add`);
  };

  const defaultColumns = [
    {
      flex: 0.05,
      minWidth: 80,
      field: "index",
      headerName: "#",
      sortable: false,
      renderCell: (params) => <div>{params.row.index}</div>,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "discountType",
      headerName: "Type",
      sortable: true,
      renderCell: (params) => (
        <div>
          {
            DISCOUNT_TYPE.find((elem) => elem.code === params.row.discountType)
              .name
          }
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 200,
      field: "productName",
      headerName: "Product",
      sortable: true,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal" }}>
          {params.row.productDetail.productName}
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCategoryStatusChange(params.row.dealId, params.row.isActive)
            }
          />
        </div>
      ),
    },

    {
      flex: 0.1,
      minWidth: 180,
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.startDate)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "endDate",
      headerName: "End Start",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.endDate).utcOffset(TIME_ZONE).format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => getDealOfTheDay(params.row.dealId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Notification">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setDealId(params.row.dealId);
                  setNotificationDialogs(true);
                }}
              >
                <Notifications style={{ color: "#F16647" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteCategory(params.row.dealId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          isLoadingDealOfTheDayDetails ||
          isLoadingDelete ||
          isLoadingNotification
        }
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.dealId}
        addButton={true}
        options={DISCOUNT_TYPE}
      />
      <NotificationModal
        addNotification={addDealOfTheDayNotification}
        id={dealId}
        emptyField={emptyField}
      />
    </Fragment>
  );
};

export default DealOfTheDayDataTabelContainer;
