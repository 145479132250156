import React, { Fragment, useContext } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import { Submit, TYPE_FELDS, TITLE, ORDER } from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createDetailsMasterPageSchema } from "../../../../utils/validationSchema";
import DetailsMasterPageContext from "../../../../context/DetailsMasterPageContext/DetailsMasterPageContext";
import {
  Create_Details_Master_Page,
  Put_Update_Details_Master_Page,
} from "../../../../Apis/detailsMasterPageApi/DetailsMasterPageApi";

const DetailsMasterPageCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(DetailsMasterPageContext);
  const { refetch, emptyField } = props;

  /**
   *
   * @param {event } e
   * create details master page  & update details master page
   */

  const {
    mutate: createDetailsMasterPage,
    isLoading: createDetailsMasterPageLoader,
  } = useMutation((formData) =>
    Create_Details_Master_Page(formData, refetch, setdialogs, emptyField)
  );

  const {
    mutate: updateDetailsMasterPage,
    isLoading: updateDetailsMasterPageLoader,
  } = useMutation((formData) =>
    Put_Update_Details_Master_Page(
      formData,
      id,
      refetch,
      setdialogs,
      emptyField
    )
  );

  const handleDetailsMasterPageCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createDetailsMasterPageSchema();
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        updateDetailsMasterPage(formData);
      } else {
        createDetailsMasterPage(formData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeDetailsMasterPage = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          createDetailsMasterPageLoader || updateDetailsMasterPageLoader
        }
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleDetailsMasterPageCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={`${TITLE} *`}
                      name="title"
                      id="title"
                      type={TYPE_FELDS.text}
                      placeholder={`${TITLE} *`}
                      value={formData.title}
                      handleChange={handleChangeDetailsMasterPage}
                    />
                    {errors.title && (
                      <span className="error-validation">{errors.title}</span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={`${ORDER} *`}
                      name="order"
                      id="order"
                      type={TYPE_FELDS.number}
                      placeholder={`${ORDER} *`}
                      value={formData.order}
                      handleChange={handleChangeDetailsMasterPage}
                    />
                    {errors.order && (
                      <span className="error-validation">{errors.order}</span>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createDetailsMasterPageLoader ||
                        updateDetailsMasterPageLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DetailsMasterPageCreateFormPopup;
