import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import CategoryAttributeDataTabelContainer from "./CategoryAttributeDataTabelContainer";
import CategoryAttributeCreateFormPopup from "./CategoryAttributeCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  ATTRIBUTE_CHID_,
  CATEGORY_ATTRIBUTE_MAP_List,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import {
  Create_Category_Attribute_map,
  Update_Category_Attribute_map,
} from "../../../../constants";
import { get_Category_Attribute_Map } from "../../../../Apis/categoryAttributeMapApi/CategoryAttributeMapApi";
import CategoryAttributeMapContext from "../../../../context/CategoryAttributeMapContext/CategoryAttributeMapContext";

const CategoryAttributeMap = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //category attribute map edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(CategoryAttributeMapContext);

  const history = useNavigate();

  /**
   * get category attribute map List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { value: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getCategoryAttributeMap", paramsObj],
    async () => await get_Category_Attribute_Map(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    },
    {}
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup category attribute map
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      setFormData({
        attributeMasterId: null,
        catId: null,
      });
      setErrors({});
    } else {
      setdialogs(condition);
      setFormData({
        attributeMasterId: null,
        catId: null,
      });
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb
        name={ATTRIBUTE_CHID_}
        slace={SLACE}
        name1={CATEGORY_ATTRIBUTE_MAP_List}
      />
      <CategoryAttributeDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={
          isEdit ? Create_Category_Attribute_map : Update_Category_Attribute_map
        }
        Component={<CategoryAttributeCreateFormPopup refetch={refetch} />}
      />
    </>
  );
};

export default CategoryAttributeMap;
