import React, { Fragment, useContext, useMemo } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  Close,
  TagName,
  TagColor,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { createProductsTagSchema } from "../../../../utils/validationSchema";
import {
  PRODUCT,
  PRODUCT_TAG_ADD,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import ProductTagMasterContext from "../../../../context/ProductTagMasterContext/ProductTagMasterContext";
import {
  Create_Product_Tag_Master,
  Get_Details_Product_Tag_Master,
  Put_Update_Product_Tag_Master,
} from "../../../../Apis/productTagMasterApi/ProductTagMasterApi";
import { ColorPicker } from "primereact/colorpicker";
import { BackButton } from "../../../../reuseableComponents/buttons";

const ProductTagMasterCreateFormPopup = () => {
  const {
    setFormData,
    id,
    isEdit,
    formData,
    errors,
    setErrors,
    setId,
    setIsEdit,
  } = useContext(ProductTagMasterContext);
  const history = useNavigate();

  let { tagId } = useParams();

  const emptyData = () => {
    setFormData((prev) => {
      return {
        ...prev,
        tagName: "",
        tagColor: "",
      };
    });
  };

  /**
   *
   * @param {event } e
   * create product tag & update product tag
   */

  const {
    mutate: createProductTagMaster,
    isLoading: createProductTagMasterLoader,
  } = useMutation((formData) =>
    Create_Product_Tag_Master(formData, history, emptyData)
  );

  const {
    mutate: updateProductTagMaster,
    isLoading: updateProductTagMasterLoader,
  } = useMutation((formData) =>
    Put_Update_Product_Tag_Master(formData, id, history, emptyData)
  );

  const handleProductTagMasterCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createProductsTagSchema();
      await schema.validate(formData, { abortEarly: false });
      if (tagId !== "add" && tagId !== -1 && isEdit === false) {
        updateProductTagMaster(formData);
      } else {
        createProductTagMaster(formData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeProductTagMaster = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  /**
   * if tag id is available to to refresh page to field fill api call
   */
  const { mutate: getProductTagMasterDetails } = useMutation((tagId) =>
    Get_Details_Product_Tag_Master(tagId, history, setFormData)
  );

  useMemo(() => {
    let valueOfNumber = Number(tagId);
    if (
      tagId !== "add" &&
      typeof valueOfNumber === "number" &&
      formData.tagName === ""
    ) {
      getProductTagMasterDetails(tagId);
      setId(tagId);
      setIsEdit(false);
    } else if (tagId === "add") {
      emptyData();
    }
    // eslint-disable-next-line
  }, [tagId]);

  /**
   * go to parent page
   */
  const redirectPrevious = () => {
    history(REDIRECT_PATH.PRODUCTS_TAG);
    emptyData();
  };

  return (
    <Fragment>
      <Breadcrumb name={PRODUCT} slace={SLACE} name1={PRODUCT_TAG_ADD} />
      <IsLoadingResponse
        isLoading={createProductTagMasterLoader || updateProductTagMasterLoader}
      />
      <BackButton
        history={history}
        path={REDIRECT_PATH.PRODUCTS_TAG}
        otherFunc={emptyData}
      />
      <div className="card-div">
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form
                  className="needs-validation"
                  onSubmit={(e) => handleProductTagMasterCreateAndUpdate(e)}
                >
                  <Row>
                    <Col md="6 mb-4">
                      <FormDataFeild
                        label={TagName}
                        name="tagName"
                        id="tagName"
                        type={TYPE_FELDS.text}
                        placeholder={TagName}
                        value={formData.tagName}
                        handleChange={handleChangeProductTagMaster}
                      />
                      {errors.tagName && (
                        <span className="error-validation">
                          {errors.tagName}
                        </span>
                      )}
                    </Col>
                    <Col md="6 mb-4">
                      <div className="flex flex-column gap-2 padding">
                        <label
                          htmlFor="username"
                          className="font-medium text-900"
                          style={{ fontSize: "14px" }}
                        >
                          {TagColor}
                        </label>
                        <ColorPicker
                          name="tagColor"
                          value={formData.tagColor}
                          onChange={handleChangeProductTagMaster}
                          className={`w-full p-inputtext-sm 
                          `}
                          id="tagColor"
                        />
                        {errors.tagColor && (
                          <span className="error-validation">
                            {errors.tagColor}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md="12 mb-4"
                      className="btn-style flex justify-content-between "
                    >
                      <Button
                        label={Close}
                        severity="danger"
                        icon="pi pi-times"
                        onClick={() => redirectPrevious()}
                      />
                      <Button
                        label={Submit}
                        icon="pi pi-check"
                        loading={
                          createProductTagMasterLoader ||
                          updateProductTagMasterLoader
                        }
                        type={TYPE_FELDS.submit}
                      />
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Fragment>
  );
};

export default ProductTagMasterCreateFormPopup;
