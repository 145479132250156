import { createSlice } from "@reduxjs/toolkit";

export const filterSlice = createSlice({
  initialState: false,
  name: "filter",
  reducers: {
    filterSidebarOpen: (state) => {
      return true;
    },
    filterSidebarClose: (state) => {
      return false;
    },
  },
});

export const { filterSidebarOpen, filterSidebarClose } = filterSlice.actions;
export default filterSlice.reducer;
