import axios from "axios";
import {
  CREATE_CATEGORY_ATTRIBUTE_MAP,
  DELETE_CATEGORY_ATTRIBUTE_MAP,
  EDIT_CATEGORY_ATTRIBUTE_MAP,
  ENABLE_DISABLE_CATEGORY_ATTRIBUTE_MAP,
  GET_CATEGORY_ATTRIBUTE_MAP,
  GET_CATEGORY_ATTRIBUTE_MAP_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";

/**
 * get category attribute map list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Category_Attribute_Map = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY_ATTRIBUTE_MAP}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add category attribute map api call
 * @param {form data} formData
 * @returns
 */

export const Create_Category_Attribute_Map = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_CATEGORY_ATTRIBUTE_MAP}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable category attribute map api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Category_Attribute_Map = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_CATEGORY_ATTRIBUTE_MAP}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details category attribute map
 * @param {*} categoryAttributeMapId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Category_Attribute_Map = (
  categoryAttributeMapId,
  setFormData,
  setdialogs
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(
      `${process.env.REACT_APP_API_URL}${GET_CATEGORY_ATTRIBUTE_MAP_DETAILS}`,
      {
        headers: { Authorization: jwt_token },
        params: { categoryAttributeMapId },
      }
    )
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        catId: data?.catId
          ? { code: data?.catId, name: data?.categoryDetail?.name }
          : null,
        attributeMasterId: data?.attributeMasterId
          ? { code: data?.attributeMasterId, name: data?.attributeMaster?.name }
          : null,
      });
      setdialogs(true);
    });
};

/**
 * update category attribute map api call
 * @param {form data} formData
 * @param {*} categoryAttributeMapId
 * @returns
 */

export const Put_Update_Category_Attribute_Map = (
  formData,
  categoryAttributeMapId
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${EDIT_CATEGORY_ATTRIBUTE_MAP}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { categoryAttributeMapId },
      }
    )
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete category attribute map api call
 * @param {form data} formData
 * @param {*} categoryAttributeMapId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Category_Attribute_Map = (
  categoryAttributeMapId,
  refetch
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(
      `${process.env.REACT_APP_API_URL}${DELETE_CATEGORY_ATTRIBUTE_MAP}`,
      {
        headers: { Authorization: jwt_token },
        params: { categoryAttributeMapId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
