import { Delete, Edit, Notifications } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { useNavigate } from "react-router-dom";
import CategoryContext from "../../../../context/CategoryContext/CategoryContext";
import {
  Delete_Category_Master,
  Enable_Disable_Category_Master,
  Get_Details_Category_Master,
  Put_Notification_Send,
} from "../../../../Apis/categoryApi/CategoryApi";
import { REDIRECT_PATH } from "../../../../Routes";
import { Paginator } from "primereact/paginator";
import { Column } from "primereact/column";
import { TreeTable } from "primereact/treetable";
import { Col, Row } from "reactstrap";
import { CreateButtonOutline } from "../../../../reuseableComponents/buttons";
import { Addnew } from "../../../../constants";
import NotificationContext from "../../../../context/NotificationContext/NotificationContext";
import NotificationModal from "../Products/NotificationModal";

const CategoryMasterDataTabelContainer = (props) => {
  const {
    setFilter,
    record,
    setFormData,
    setId,
    setIsEdit,
    setSelectedParentCategory,
    catId,
    setCatId,
  } = useContext(CategoryContext);
  const {
    setNotificationDialogs,
    formData: notificationFormData,
    setFormData: setNotificationFormData,
  } = useContext(NotificationContext);
  const { data, refetch, setPagination, pagination } = props;
  const history = useNavigate();

  /**
   * get category Details
   */
  const { mutate: categoryDetails, isLoading: isLoadingCategoryDetails } =
    useMutation((catId) =>
      Get_Details_Category_Master(
        catId,
        history,
        setFormData,
        setSelectedParentCategory
      )
    );

  const GetCategory = (catId) => {
    categoryDetails(catId);
    setId(catId);
    setIsEdit(false);
  };

  /**
   * category notification
   */
  const { mutate: categoryNotification, isLoading: isLoadingNotification } =
    useMutation((formData) => Put_Notification_Send(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          emptyField();
        }
      },
    });

  const emptyField = () => {
    setCatId("");
    setNotificationFormData({
      image: "",
      imageUrl: "",
      title: "",
      template: "",
    });
    setNotificationDialogs(false);
  };

  const addCategoryNotification = (catId) => {
    let formData = {
      catId,
      ...notificationFormData,
    };
    const { image, ...newData } = formData;
    categoryNotification(newData);
  };
  /**
   * delete category
   */
  const { mutate: categoryDelete, isLoading: isLoadingDelete } = useMutation(
    (catId) => Delete_Category_Master(catId, refetch)
  );

  const DeleteCategory = async (catId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      categoryDelete(catId);
    }
  };

  /**
   * enable / disable category
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Category_Master(formData, refetch)
  );

  const handleCategoryStatusChange = (catId, status) => {
    let formDate = {
      catId: catId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.catId === catId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.CATEGORY}/add`);
  };

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          isLoadingCategoryDetails || isLoadingDelete || isLoadingNotification
        }
      />

      <div className="tabel-contant">
        <div className={`tabelContent`}>
          <div className="tabelSearch">
            <div className="p-datatable-header">
              <Row>
                <Col md="12 mb-4" className="d-flex justify-content-end ">
                  <CreateButtonOutline
                    click={handleOpenandClosePopup}
                    name={Addnew}
                    variant="primary"
                    icon="pi-user-plus"
                  />
                </Col>
              </Row>
              <TreeTable
                value={data?.payload?.data.map((elem) => {
                  return {
                    key: elem?.catId,
                    data: {
                      image: (
                        <input
                          type="image"
                          img="true"
                          src={
                            elem?.image
                              ? elem?.image
                              : require("../../../../assets/Images/ImageNotFound.png")
                          }
                          alt="photo"
                          style={{ height: "2rem" }}
                        />
                      ),
                      category: elem?.name || "-",
                      desc: elem?.desc || "-",
                      status: (
                        <div className="flex justify-content-center">
                          <InputSwitch
                            checked={elem.isActive === true}
                            onChange={() =>
                              handleCategoryStatusChange(
                                elem.catId,
                                elem.isActive
                              )
                            }
                          />
                        </div>
                      ),
                      action: (
                        <div className="d-flex">
                          <div style={{ paddingRight: "5px" }}>
                            <Tooltip title="Edit">
                              <IconButton
                                aria-label="edit"
                                onClick={() => GetCategory(elem?.catId)}
                              >
                                <Edit style={{ color: "#7366ff" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div style={{ paddingRight: "5px" }}>
                            <Tooltip title="Notification">
                              <IconButton
                                aria-label="edit"
                                onClick={() => {
                                  setCatId(elem?.catId);
                                  setNotificationDialogs(true);
                                }}
                              >
                                <Notifications style={{ color: "#F16647" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div style={{ paddingRight: "5px" }}>
                            <Tooltip title="Delete">
                              <IconButton
                                aria-label="delete"
                                onClick={() => DeleteCategory(elem?.catId)}
                              >
                                <Delete style={{ color: "red" }} />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      ),
                    },
                    children: elem?.subCategories?.map((child) => {
                      return {
                        key: child?.catId,
                        data: {
                          image: (
                            <input
                              type="image"
                              img="true"
                              src={
                                child?.image
                                  ? child?.image
                                  : require("../../../../assets/Images/ImageNotFound.png")
                              }
                              alt="photo"
                              style={{ height: "2rem" }}
                            />
                          ),
                          category: child?.name || "-",
                          desc: child?.desc || "-",
                          status: (
                            <div className="flex justify-content-center">
                              <InputSwitch
                                checked={child.isActive === true}
                                onChange={() =>
                                  handleCategoryStatusChange(
                                    child.catId,
                                    child.isActive
                                  )
                                }
                              />
                            </div>
                          ),
                          action: (
                            <div className="d-flex">
                              <div style={{ paddingRight: "5px" }}>
                                <Tooltip title="Edit">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => GetCategory(child?.catId)}
                                  >
                                    <Edit style={{ color: "#7366ff" }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div style={{ paddingRight: "5px" }}>
                                <Tooltip title="Notification">
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      setCatId(child?.catId);
                                      setNotificationDialogs(true);
                                    }}
                                  >
                                    <Notifications
                                      style={{ color: "#F16647" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              </div>
                              <div style={{ paddingRight: "5px" }}>
                                <Tooltip title="Delete">
                                  <IconButton
                                    aria-label="delete"
                                    onClick={() => DeleteCategory(child?.catId)}
                                  >
                                    <Delete style={{ color: "red" }} />
                                  </IconButton>
                                </Tooltip>
                              </div>
                            </div>
                          ),
                        },
                        children: child?.subCategories?.map((subChild) => {
                          return {
                            key: subChild?.catId,
                            data: {
                              image: (
                                <input
                                  type="image"
                                  img="true"
                                  src={
                                    subChild?.image
                                      ? subChild?.image
                                      : require("../../../../assets/Images/ImageNotFound.png")
                                  }
                                  alt="photo"
                                  style={{ height: "2rem" }}
                                />
                              ),
                              category: subChild?.name || "-",
                              desc: subChild?.desc || "-",
                              status: (
                                <div className="flex justify-content-center">
                                  <InputSwitch
                                    checked={subChild.isActive === true}
                                    onChange={() =>
                                      handleCategoryStatusChange(
                                        subChild.catId,
                                        subChild.isActive
                                      )
                                    }
                                  />
                                </div>
                              ),
                              action: (
                                <div className="d-flex">
                                  <div style={{ paddingRight: "5px" }}>
                                    <Tooltip title="Edit">
                                      <IconButton
                                        aria-label="edit"
                                        onClick={() =>
                                          GetCategory(subChild?.catId)
                                        }
                                      >
                                        <Edit style={{ color: "#7366ff" }} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div style={{ paddingRight: "5px" }}>
                                    <Tooltip title="Notification">
                                      <IconButton
                                        aria-label="edit"
                                        onClick={() => {
                                          setCatId(subChild?.catId);
                                          setNotificationDialogs(true);
                                        }}
                                      >
                                        <Notifications
                                          style={{ color: "#F16647" }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                  <div style={{ paddingRight: "5px" }}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        aria-label="delete"
                                        onClick={() =>
                                          DeleteCategory(subChild?.catId)
                                        }
                                      >
                                        <Delete style={{ color: "red" }} />
                                      </IconButton>
                                    </Tooltip>
                                  </div>
                                </div>
                              ),
                            },
                          };
                        }),
                      };
                    }),
                  };
                })}
                onFilter={(e) => setFilter(e?.filters?.category?.value)}
              >
                <Column
                  field="image"
                  header="Image"
                  expander
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="category"
                  header="Category"
                  filter
                  filterPlaceholder="Filter by product name"
                  filterField="Category Name"
                  filterMatchMode="contains"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="desc"
                  header="Desc"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="status"
                  header="Status"
                  style={{ width: "250px" }}
                ></Column>
                <Column
                  field="action"
                  header="Action"
                  style={{ width: "250px" }}
                ></Column>
              </TreeTable>
              <Paginator
                first={pagination.first}
                rows={pagination.pageCount}
                totalRecords={record}
                rowsPerPageOptions={[10, 20, 30]}
                onPageChange={(p) =>
                  setPagination({
                    first: p.first,
                    page: p.page,
                    pageCount: p.rows,
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
      <NotificationModal
        addNotification={addCategoryNotification}
        id={catId}
        emptyField={emptyField}
      />
    </Fragment>
  );
};

export default CategoryMasterDataTabelContainer;
