import React, { useState } from "react";
import ProductsContext from "./ProductsContext";
import { randomStr } from "../../utils/Helper";

const ProductsContextState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");
  const [subCatIdData, setSubCatIdData] = useState({
    subCat: [],
    subToSub: [],
  });

  /**brand master edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [notificationDialog, setNotificationDialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [id, setId] = useState(null);
  const [isImageUpload, setIsImageUpload] = useState(false);
  const [formData, setFormData] = useState({
    productName: "",
    sku: "",
    sortDesc: "",
    longDesc: "",
    mrp: "",
    sellPrice: "",
    catId: "",
    slugUrl: "",
    seoTitle: "",
    seoDesc: "",
    seoKeyword: "",
    brandId: "",
    isReturn: false,
    tax: "",
    taxType: "",
    taxPer: 0.0,
    prodType: "simple",
    tags: "",
    videoLink: "",
    images: [
      {
        id: randomStr(2),
        imgAltText: "",
        imagePath: "",
        order: 1,
        isDefault: false,
      },
    ],
    attributes: [
      {
        id: randomStr(2),
        attributeId: [],
        attributeArr: "",
        attributeType: "",
        mrp: "",
        sellPrice: "",
        taxAmt: "",
        taxType: "",
        taxPer: "",
        isDefault: true,
      },
    ],
    logo: "",
    type: "",
    extraProductIds: "",
    referenceProductIds: "",
    subCat: "",
    subToSubCat: "",
  });
  const [productId, setProductId] = useState("");
  const [attSelect, setAttSelect] = useState({});
  const [selectAttribute, setSelectAttribute] = useState([]);
  const [selectedAttributeCopy, setSelectedAttributeCopy] = useState([]);
  const [attributeImages, setAttributeImages] = useState({});
  const [attributeMasterId, setAttributeMasterId] = useState("");
  const [attributeMasterIndex, setAttributeMasterIndex] = useState("");
  const [saveAttData, setSaveAttData] = useState(false);
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState(1 / 1);
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");
  const [dialogModal, setdialogsModal] = useState(false);

  return (
    <ProductsContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        id,
        setId,
        formData,
        setFormData,
        yes,
        setYes,
        errors,
        setErrors,

        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        imageType,
        setImageType,
        dialogModal,
        setdialogsModal,
        subCatIdData,
        setSubCatIdData,
        selectAttribute,
        setSelectAttribute,
        selectedAttributeCopy,
        setSelectedAttributeCopy,
        saveAttData,
        setSaveAttData,
        attSelect,
        setAttSelect,
        attributeImages,
        setAttributeImages,
        attributeMasterId,
        setAttributeMasterId,
        attributeMasterIndex,
        setAttributeMasterIndex,
        isImageUpload,
        setIsImageUpload,
        notificationDialog,
        setNotificationDialogs,
        productId,
        setProductId,
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsContextState;
