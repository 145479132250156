import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import AttributeMasterContext from "../../../../context/AttributeMasterContext/AttributeMasterContext";
import {
  Delete_Attribute_Master,
  Enable_Disable_Attribute_Master,
  Get_Details_Attribute_Master,
} from "../../../../Apis/attributeMasterApi/AttributeMasterApi";

const AttributeMasterDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(AttributeMasterContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get attribute master Details
   */
  const {
    mutate: attributeMasterDetails,
    isLoading: isLoadingAttributeMasterDetails,
  } = useMutation((attributeMasterId) =>
    Get_Details_Attribute_Master(attributeMasterId, setFormData, setdialogs)
  );

  const GetAttributeDetails = (attributeMasterId) => {
    attributeMasterDetails(attributeMasterId);
    setId(attributeMasterId);
    setIsEdit(false);
  };

  /**
   * delete attribute master
   */
  const { mutate: attributeMasterDelete, isLoading: isLoadingDelete } =
    useMutation((attributeMasterId) =>
      Delete_Attribute_Master(attributeMasterId, refetch)
    );

  const DeleteAttributeMaster = async (attributeMasterId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      attributeMasterDelete(attributeMasterId);
    }
  };

  /**
   * enable / disable attribute master
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Attribute_Master(formData, refetch)
  );

  const handleAttributeMasterStatusChange = (attributeMasterId, status) => {
    let formDate = {
      attributeMasterId: attributeMasterId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.attributeMasterId === attributeMasterId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "name",
      headerName: "Attribute Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "attributeType",
      headerName: "Attribute Type",
      renderCell: (params) =>
        params.row.attributeType
          ? params.row.attributeType === 1
            ? "Box"
            : params.row.attributeType === 2
            ? "Drop Down"
            : params.row.attributeType === 3
            ? "Image"
            : "--"
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleAttributeMasterStatusChange(
                params.row.attributeMasterId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  GetAttributeDetails(params.row.attributeMasterId)
                }
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() =>
                  DeleteAttributeMaster(params.row.attributeMasterId)
                }
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingAttributeMasterDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.attributeMasterId}
        addButton={true}
      />
    </Fragment>
  );
};

export default AttributeMasterDataTabelContainer;
