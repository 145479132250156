import React, { Fragment, useContext, useState } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation, useQuery } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import { Submit, TYPE_FELDS, Pin_Code, Store_ } from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createDeliveryPincodeSchema } from "../../../../utils/validationSchema";
import { get_Pincode_Master } from "../../../../Apis/pincodeMasterApi/PincodeMasterApi";
import { InputSwitch } from "primereact/inputswitch";
import DeliveryPincodeContext from "../../../../context/DeliveryPincodeContext/DeliveryPincodeContext";
import { get_Store } from "../../../../Apis/storeApi/StoreApi";
import {
  Create_Delivery_Pincode,
  Put_Update_Delivery_Pincode,
} from "../../../../Apis/deliveryPincodeApi/DeliveryPincodeApi";

const DeliveryPincodeCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(DeliveryPincodeContext);
  const { refetch, emptyField } = props;
  const [filterDrop, setFilterDrop] = useState({
    storeFilter: "",
    pincodeFilter: "",
  });

  /**
   *
   * @param {event } e
   * create delivery pincode & update delivery pincode
   */

  const {
    mutate: createDeliveryPincode,
    isLoading: createDeliveryPincodeLoader,
  } = useMutation((formData) => Create_Delivery_Pincode(formData), {
    onSuccess: (response) => {
      if (response.status === 200) {
        refetch();
        setdialogs(false);
        emptyField();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      }
    },
  });

  const {
    mutate: updateDeliveryPincode,
    isLoading: updateDeliveryPincodeLoader,
  } = useMutation((formData) => Put_Update_Delivery_Pincode(formData, id), {
    onSuccess: (response) => {
      refetch();
      setdialogs(false);
      emptyField();
      setErrors({});
      ToastMessage(response.status, response.data.message);
    },
  });

  let paramsObj = {
    showAll: true,
    search: { name: filterDrop.storeFilter },
  };
  let { data: storeDrop } = useQuery(
    ["getStoreDropdownInDeliveryPincode", paramsObj],
    async () => await get_Store(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const storeId = storeDrop?.payload?.data?.length
    ? storeDrop?.payload?.data
        .filter((ele) => ele.name === "YESFAB")
        .map((elem) => {
          return {
            storeId: elem?.storeId,
          };
        })
    : null;

  const handleDeliveryPincodeCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createDeliveryPincodeSchema({
        slotOfType: formData.slotOffType?.code,
      });
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        let newData = {
          ...formData,
          pincodeMasterId:
            formData.pincodeMasterId?.map((elem) => {
              return elem.code;
            }) || null,
          storeId: formData.storeId?.code || null,
        };
        updateDeliveryPincode(newData);
      } else {
        let newData = {
          ...formData,
          pincodeMasterId:
            formData.pincodeMasterId?.map((elem) => {
              return elem.code;
            }) || null,
          storeId: storeId[0].storeId || null,
        };
        createDeliveryPincode(newData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeDeliveryPincode = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  let paramObjPincodeMaster = {
    showAll: true,
    search: { pincode: filterDrop.pincodeFilter },
  };
  let { data: pincodeMasterDrop } = useQuery(
    ["getPincodeDrop", paramObjPincodeMaster],
    async () => await get_Pincode_Master(paramObjPincodeMaster),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={createDeliveryPincodeLoader || updateDeliveryPincodeLoader}
      />
      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handleDeliveryPincodeCreateAndUpdate(e)}
              >
                <Row>
                  {/* <Col md="4 mb-4">
                    <FormDataFeild
                      label={Store_}
                      name="storeId"
                      id="storeId"
                      placeholder={Store_}
                      value={formData.storeId}
                      handleChange={handleChangeDeliveryPincode}
                      option={storeDrop?.payload?.data?.map((elem) => {
                        return {
                          name: elem?.name,
                          code: elem?.storeId,
                        };
                      })}
                      dropdown={true}
                      search={(e) => {
                        setFilterDrop((prev) => {
                          return {
                            ...prev,
                            storeFilter: e.target.value,
                          };
                        });
                      }}
                    />
                    {errors.storeId && (
                      <span className="error-validation">{errors.storeId}</span>
                    )}
                  </Col> */}
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${Pin_Code} *`}
                      name="pincodeMasterId"
                      id="pincodeMasterId"
                      placeholder={`${Pin_Code} *`}
                      value={formData.pincodeMasterId}
                      handleChange={handleChangeDeliveryPincode}
                      multiOption={pincodeMasterDrop?.payload?.data?.map(
                        (elem) => {
                          return {
                            name: elem?.pincode,
                            code: elem?.pincodeMasterId,
                          };
                        }
                      )}
                      multiDropdown={true}
                      search={(e) => {
                        setFilterDrop((prev) => {
                          return {
                            ...prev,
                            pincodeFilter: e.target.value,
                          };
                        });
                      }}
                    />
                    {errors.storeId && (
                      <span className="error-validation">{errors.storeId}</span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <div className="flex flex-column gap-2 padding">
                      <label
                        htmlFor="username"
                        className="font-medium text-900"
                        style={{ fontSize: "14px" }}
                      >
                        {"Is Delivery Available"}
                      </label>
                      <InputSwitch
                        name="isDeliveryAvailable"
                        id="isDeliveryAvailable"
                        checked={formData.isDeliveryAvailable}
                        onChange={handleChangeDeliveryPincode}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createDeliveryPincodeLoader ||
                        updateDeliveryPincodeLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default DeliveryPincodeCreateFormPopup;
