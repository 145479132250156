import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  PROMOCODE_,
  PROMOCODE_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import PromoCodeContext from "../../../../context/PromoCodeContext/PromoCodeContext";
import PromoCodeDataTabelContainer from "./PromoCodeDataTabelContainer";
import { get_Promo_Code } from "../../../../Apis/promoCodeApi/PromoCodeApi";

const PromoCodesList = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
  } = useContext(PromoCodeContext);

  const history = useNavigate();

  /**
   * get banner slider List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    // search: { promoCode: 2 },
  };

  if (filter.length) {
    paramsObj["search"] = { promoCode: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getPromoCode", paramsObj],
    async () => await get_Promo_Code(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  return (
    <>
      <Breadcrumb name={PROMOCODE_} slace={SLACE} name1={PROMOCODE_LIST} />

      <PromoCodeDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default PromoCodesList;
