import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Login_ } from "../Apis/AuthApi";
import { FORGOT, NUMBER, TypeNumber } from "../constants";
import { LoginInput } from "../reuseable/Inputs";
import "../assets/css/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { IsLoadingResponse } from "../utils/Helper";
import { Form } from "reactstrap";
import { useMutation } from "react-query";
import { Button } from "primereact/button";
import { REDIRECT_PATH } from "../Routes";

const ForgotPassword = () => {
  const history = useNavigate();

  const [formData, setFormData] = useState({
    dialCode: "",
    phone: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const LoginToWebsite = async (e) => {
    e.preventDefault();
    if (formData.phone.length !== 0 && formData.password.length !== 0) {
      Login(formData);
    }
  };

  const { mutate: Login, isLoading: LoginLoading } = useMutation((formData) =>
    Login_(formData, history)
  );

  return (
    <>
      <IsLoadingResponse isLoading={LoginLoading} />
      <div className="login_background">
        <div className="login_main">
          <div className="login_center_container">
            <div className="login_content_container">
              <div>
                <div className="header">
                  <div>{FORGOT}</div>
                  <span>Enter your email to reset your password</span>
                </div>
                <Form onSubmit={(e) => LoginToWebsite(e)}>
                  <div className="flex flex-column mb-2 ">
                    <LoginInput
                      Label={NUMBER}
                      type="number"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      maxlangth={TypeNumber.PHONE}
                    />
                  </div>
                  <div className="flex justify-content-between flex-wrap gap-2 ">
                    <Button
                      label="Cancel"
                      outlined
                      className="p-button p-component p-button-outlined flex-auto"
                      style={{ height: "44px" }}
                      onClick={() => history(REDIRECT_PATH.AUTH)}
                    />
                    <Button
                      label="Submit"
                      className="p-button p-component flex-auto "
                      style={{ height: "44px" }}
                      type="button"
                      onClick={() => history(REDIRECT_PATH.RESET_PASSWORD)}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
