import React, { Fragment, useState } from "react";
import DashboardItems from "./DashboardItems";
import { Row } from "react-bootstrap";
import Breadcrumb from "../../../reuseable/Breadcrumb/Breadcrumb";
import {
  DASHBOARD,
  E_COMMERCE,
  SLACE,
} from "../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { get_Dashboard } from "../../../Apis/userApi/UserApi";
import { useQuery } from "react-query";
import { DashboardRedirect } from "../../../constants";
import { DashboardSkeleton } from "../../../utils/DataTabelSkelaton";

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState([]);

  let { isLoading: getDashboardLoader } = useQuery(
    ["getDashboard"],
    async () => await get_Dashboard(),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          let value = [];
          for (const i in response?.payload?.data) {
            for (const iterator of DashboardRedirect) {
              if (response?.payload?.data[i].name === iterator.name) {
                value[i] = {
                  ...response?.payload?.data[i],
                  redirect: iterator.redirect,
                };
              }
            }
          }
          setDashboardData(value);
        }
      },
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  console.log("dashboardData >> ", dashboardData);

  return (
    <Fragment>
      <Breadcrumb name={DASHBOARD} slace={SLACE} name1={E_COMMERCE} />
      {getDashboardLoader ? (
        <DashboardSkeleton />
      ) : (
        <div className="grid card-div grid-nogutter border">
          <Row>
            {dashboardData?.length
              ? dashboardData
                  ?.filter((ele) => ele.name !== "Store Count")
                  ?.map((elem) => <DashboardItems elem={elem} />)
              : []}
          </Row>
        </div>
      )}
    </Fragment>
  );
};

export default Dashboard;
