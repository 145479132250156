import axios from "axios";
import {
  CATEGORY_NOTIFICATION,
  CHECK_SLUG_CATEGORY,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  ENABLE_DISABLE_CATEGORY,
  GET_CATEGORY,
  GET_CATEGORY_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";

/**
 * get category master list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Category_Master = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add category master api call
 * @param {form data} formData
 * @param {category master list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Category_Master = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_CATEGORY}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.CATEGORY}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable category master api call
 * @param {form data as isActive & catId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Category_Master = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_CATEGORY}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { catId: formData.catId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details category master
 * @param {*} catId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Category_Master = (
  catId,
  history,
  setFormData,
  setSelectedParentCategory
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_CATEGORY_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      let responseCategory = await get_Category_Master(paramObj);
      [responseCategory] = responseCategory?.payload?.data?.filter(
        (elem) => elem?.catId === data?.pCatId
      );
      setSelectedParentCategory({
        code: data?.parentCategory?.parentCategory?.catId,
        name: data?.parentCategory?.parentCategory?.name,
      });
      setFormData({
        name: data ? data?.name : "",
        pCatId: data?.parentCategory?.parentCategory?.catId
          ? {
              code: data?.parentCategory?.parentCategory?.catId,
              name: data?.parentCategory?.parentCategory?.name,
            }
          : data?.parentCategory
          ? {
              code: data?.parentCategory?.catId,
              name: data?.parentCategory?.name,
            }
          : {},
        subCatId:
          data?.parentCategory?.parentCategory !== "" &&
          data?.parentCategory?.parentCategory?.catId &&
          data?.parentCategory != null &&
          data?.parentCategory?.catId
            ? {
                code: data?.parentCategory?.catId,
                name: data?.parentCategory?.name,
              }
            : {},
        desc: data ? data?.desc : "",
        image: data ? data?.image : "",
        slugUrl: data ? data?.slugUrl : "",
        seoTitle: data ? data?.seoTitle : "",
        seoDesc: data ? data?.seoDesc : "",
        seoKeyword: data ? data?.seoKeyword : "",
        schemaTag: data ? data?.schemaTag : "",
        order: data ? data?.order : "",
        isHome: data ? data?.isHome : false,
        homeOrder: data ? data?.homeOrder : 1,
      });
      history(`${REDIRECT_PATH.CATEGORY}/${catId}`);
    });
};

/**
 * update category master api call
 * @param {form data} formData
 * @param {*} catId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Category_Master = (
  formData,
  catId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_CATEGORY}`, formData, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.CATEGORY}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete category master api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Category_Master = (catId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_CATEGORY}`, {
      headers: { Authorization: jwt_token },
      params: { catId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug category master api call
 * @param {form data} formData
 * @param {*} catId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_CATEGORY}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Put_Notification_Send = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CATEGORY_NOTIFICATION}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      ToastMessage(message.status, message.data.message);
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
