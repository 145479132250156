import React, { useState } from "react";
import TempleMasterContext from "./TempleMasterContext";

const TempleMasterState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**organization edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [templeId, setTempleId] = useState(null);
  const [formData, setFormData] = useState({
    templeName: null,
    mobile: null,
    email: null,
    panCardNo: null,
    gstNumber: null,
    address: null,
    city: null,
    state: null,
    country: null,
    adminUser: {
      name: null,
      phone: null,
      email: null,
      password: null,
      confirmPassword: null,
    },
  });

  return (
    <TempleMasterContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        templeId,
        setTempleId,
        formData,
        setFormData,
      }}
    >
      {props.children}
    </TempleMasterContext.Provider>
  );
};

export default TempleMasterState;
