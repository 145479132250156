import React, { Fragment, useContext } from "react";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import {
  Submit,
  TYPE_FELDS,
  City,
  State,
  Country,
  Pin_Code,
} from "../../../../constants";
import { Button } from "primereact/button";
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import { createPincodeMasterSchema } from "../../../../utils/validationSchema";
import PincodeMasterContext from "../../../../context/PincodeMasterContext/PincodeMasterContext";
import {
  Create_Pincode_Master,
  Put_Update_Pincode_Master,
} from "../../../../Apis/pincodeMasterApi/PincodeMasterApi";
import { InputSwitch } from "primereact/inputswitch";

const PincodeMasterCreateFormPopup = (props) => {
  const { setFormData, id, isEdit, setdialogs, formData, errors, setErrors } =
    useContext(PincodeMasterContext);
  const { refetch, emptyField } = props;

  /**
   *
   * @param {event } e
   * create pincode master & update pincode master
   */

  const { mutate: createPincodeMaster, isLoading: createPincodeMasterLoader } =
    useMutation((formData) => Create_Pincode_Master(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyField();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    });

  const { mutate: updatePincodeMaster, isLoading: updatePincodeMasterLoader } =
    useMutation((formData) => Put_Update_Pincode_Master(formData, id), {
      onSuccess: (response) => {
        refetch();
        setdialogs(false);
        emptyField();
        setErrors({});
        ToastMessage(response.status, response.data.message);
      },
    });

  const handlePincodeMasterCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createPincodeMasterSchema({
        slotOfType: formData.slotOffType?.code,
      });
      await schema.validate(formData, { abortEarly: false });
      if (id !== -1 && isEdit === false) {
        updatePincodeMaster(formData);
      } else {
        createPincodeMaster(formData);
      }
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(
          Object.keys(validationErrors)[0]
        );
        inputField[0].focus();
      }
    }
  };

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangePincodeMaster = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={createPincodeMasterLoader || updatePincodeMasterLoader}
      />

      <Row>
        <Col sm="12">
          <Card>
            <CardBody>
              <Form
                className="needs-validation"
                onSubmit={(e) => handlePincodeMasterCreateAndUpdate(e)}
              >
                <Row>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${Pin_Code} *`}
                      name="pincode"
                      id="pincode"
                      type={TYPE_FELDS.text}
                      placeholder={`${Pin_Code} *`}
                      value={formData.pincode}
                      handleChange={handleChangePincodeMaster}
                    />
                    {errors.pincode && (
                      <span className="error-validation">{errors.pincode}</span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${City} *`}
                      name="city"
                      id="city"
                      type={TYPE_FELDS.text}
                      placeholder={`${City} *`}
                      value={formData.city}
                      handleChange={handleChangePincodeMaster}
                    />
                    {errors.city && (
                      <span className="error-validation">{errors.city}</span>
                    )}
                  </Col>
                  <Col md="4 mb-4">
                    <FormDataFeild
                      label={`${State} *`}
                      name="state"
                      id="state"
                      type={TYPE_FELDS.text}
                      placeholder={`${State} *`}
                      value={formData.state}
                      handleChange={handleChangePincodeMaster}
                    />
                    {errors.state && (
                      <span className="error-validation">{errors.state}</span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <FormDataFeild
                      label={`${Country} *`}
                      name="county"
                      id="county"
                      type={TYPE_FELDS.text}
                      placeholder={`${Country} *`}
                      value={formData.county}
                      handleChange={handleChangePincodeMaster}
                    />
                    {errors.county && (
                      <span className="error-validation">{errors.county}</span>
                    )}
                  </Col>
                  <Col md="6 mb-4">
                    <div className="flex flex-column gap-2 padding">
                      <label
                        htmlFor="username"
                        className="font-medium text-900"
                        style={{ fontSize: "14px" }}
                      >
                        {"Is All"}
                      </label>
                      <InputSwitch
                        name="isAll"
                        id="isAll"
                        checked={formData.isAll}
                        onChange={handleChangePincodeMaster}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="12 mb-4" className="btn-style">
                    <Button
                      label={Submit}
                      icon="pi pi-check"
                      loading={
                        createPincodeMasterLoader || updatePincodeMasterLoader
                      }
                      type={TYPE_FELDS.submit}
                    />
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default PincodeMasterCreateFormPopup;
