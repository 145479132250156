import axios from "axios";
import {
  CHECK_SLUG_PROMOCODE,
  CREATE_PROMOCODE,
  DELETE_PROMOCODE,
  EDIT_PROMOCODE,
  ENABLE_DISABLE_PROMOCODE,
  GET_PROMOCODE,
  GET_PROMOCODE_DETAILS,
  PROMOCODE_NOTIFICATION,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";
import { get_Products } from "../productsApi/ProductsApi";
import { get_Category_Master } from "../categoryApi/CategoryApi";
import { get_Store } from "../storeApi/StoreApi";
import { DISCOUNT_APPLY_TYPE, DISCOUNT_TYPE } from "../../constants";

/**
 * get promo code list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Promo_Code = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PROMOCODE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add promo code api call
 * @param {form data} formData
 * @param {promo code list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Promo_Code = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_PROMOCODE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.PROMOCODE}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable promo code api call
 * @param {form data as isActive & promoCodeId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Promo_Code = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_PROMOCODE}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { promoCodeId: formData.promoCodeId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details promo code
 * @param {*} promoCodeId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Promo_Code = (promoCodeId, history, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PROMOCODE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { promoCodeId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };

      let responseStore = await get_Store(paramObj);
      [responseStore] = responseStore?.payload?.data?.filter(
        (elem) => elem?.storeId === data?.storeId
      );
      let responseProduct = await get_Products(paramObj);
      [responseProduct] = responseProduct?.payload?.data?.filter(
        (elem) => elem?.productId === data?.productId
      );
      let responseCategory = await get_Category_Master(paramObj);
      [responseCategory] = responseCategory?.payload?.data?.filter(
        (elem) => elem?.catId === data?.catId
      );
      let [responseDiscountType] = DISCOUNT_TYPE?.filter(
        (elem) => elem?.code === data?.discountType
      );
      let [responseApplyType] = DISCOUNT_APPLY_TYPE?.filter(
        (elem) => elem?.code === data?.applyType
      );
      setFormData({
        storeId: data?.storeId,
        applyType: data
          ? {
              name: responseApplyType?.name,
              code: responseApplyType?.code,
            }
          : null,
        discountType: data
          ? {
              name: responseDiscountType?.name,
              code: responseDiscountType?.code,
            }
          : null,

        discountValue: data?.discountValue,
        maxDiscountAmount: data?.maxDiscountAmount,
        minOrderAmount: data?.minOrderAmount,

        promoCode: data ? data?.promoCode : "",
        assignStores: data
          ? {
              name: responseStore?.name,
              code: responseStore?.storeId,
            }
          : null,
        productId: data?.productId
          ? {
              code: responseProduct?.productId,
              name: responseProduct?.productName,
            }
          : null,
        catId: data?.catId
          ? {
              code: responseCategory.catId,
              name: responseCategory.name,
            }
          : null,
        startDate: data
          ? [new Date(data?.startDate), new Date(data?.endDate)]
          : "",
        endDate: data ? data?.endDate : "",
      });
      history(`${REDIRECT_PATH.PROMOCODE}/${promoCodeId}`);
      return res;
    });
};

/**
 * update promo code api call
 * @param {form data} formData
 * @param {*} promoCodeId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Promo_Code = (
  formData,
  promoCodeId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_PROMOCODE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { promoCodeId },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.PROMOCODE}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete promo code api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Promo_Code = (promoCodeId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_PROMOCODE}`, {
      headers: { Authorization: jwt_token },
      params: { promoCodeId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug promo code api call
 * @param {form data} formData
 * @param {*} catId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_PROMOCODE}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

export const Put_Notification_Send = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${PROMOCODE_NOTIFICATION}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      ToastMessage(message.status, message.data.message);
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
