import { createSlice } from "@reduxjs/toolkit";

export const selectAttributePopupSlice = createSlice({
  initialState: { attribute: false, attributeImageUpload: false },
  name: "selectAttributePopup",
  reducers: {
    selectAttributeOpen: (state) => {
      return { ...state, attribute: true };
    },
    selectAttributeClose: (state) => {
      return { ...state, attribute: false };
    },
    attributeImageUploadOpen: (state) => {
      return { ...state, attributeImageUpload: true, attribute: false };
    },
    attributeImageUploadClose: (state) => {
      return { ...state, attributeImageUpload: false, attribute: false };
    },
  },
});

export const {
  selectAttributeOpen,
  selectAttributeClose,
  attributeImageUploadOpen,
  attributeImageUploadClose,
} = selectAttributePopupSlice.actions;
export default selectAttributePopupSlice.reducer;
