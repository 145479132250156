import { Delete, Edit, Notifications } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import {
  Delete_Promo_Code,
  Enable_Disable_Promo_Code,
  Get_Details_Promo_Code,
  Put_Notification_Send,
} from "../../../../Apis/promoCodeApi/PromoCodeApi";
import PromoCodeContext from "../../../../context/PromoCodeContext/PromoCodeContext";
import NotificationContext from "../../../../context/NotificationContext/NotificationContext";
import NotificationModal from "../Products/NotificationModal";

const PromoCodeDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    setAspect,
    setFileType,
    promoCodeId,
    setPromoCodeId,
  } = useContext(PromoCodeContext);
  const {
    setNotificationDialogs,
    formData: notificationFormData,
    setFormData: setNotificationFormData,
  } = useContext(NotificationContext);
  const { isLoading, data, refetch } = props;
  const history = useNavigate();

  /**
   * get promo code Details
   */
  const { mutate: promoCodeDetails, isLoading: isLoadingPromoCodeDetails } =
    useMutation((promoCodeId) =>
      Get_Details_Promo_Code(
        promoCodeId,
        history,
        setFormData,
        setAspect,
        setFileType
      )
    );

  const getPromoCode = (promoCodeId) => {
    promoCodeDetails(promoCodeId);
    setId(promoCodeId);
    setIsEdit(false);
  };

  /**
   * promo code notification
   */
  const { mutate: promoCodeNotification, isLoading: isLoadingNotification } =
    useMutation((formData) => Put_Notification_Send(formData), {
      onSuccess: (response) => {
        if (response.status === 200) {
          emptyField();
        }
      },
    });

  const emptyField = () => {
    setPromoCodeId("");
    setNotificationFormData({
      image: "",
      imageUrl: "",
      title: "",
      template: "",
    });
    setNotificationDialogs(false);
  };

  const addPromoCodeNotification = (promoCodeId) => {
    let formData = {
      promoCodeId,
      ...notificationFormData,
    };
    const { image, ...newData } = formData;
    promoCodeNotification(newData);
  };

  /**
   * delete promo code
   */
  const { mutate: promoCodeDelete, isLoading: isLoadingDelete } = useMutation(
    (promoCodeId) => Delete_Promo_Code(promoCodeId, refetch)
  );

  const DeleteCategory = async (promoCodeId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      promoCodeDelete(promoCodeId);
    }
  };

  /**
   * enable / disable promo code
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Promo_Code(formData, refetch)
  );

  const handleCategoryStatusChange = (promoCodeId, status) => {
    let formDate = {
      promoCodeId: promoCodeId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.promoCodeId === promoCodeId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.PROMOCODE}/add`);
  };

  const defaultColumns = [
    {
      flex: 0.05,
      minWidth: 80,
      field: "index",
      headerName: "#",
      sortable: false,
      renderCell: (params) => <div>{params.row.index}</div>,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "promoCode",
      headerName: "Promo Code",
      sortable: true,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "discountType",
      headerName: "Type",
      sortable: true,
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCategoryStatusChange(
                params.row.promoCodeId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },

    {
      flex: 0.1,
      minWidth: 180,
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.startDate)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "endDate",
      headerName: "End Start",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.endDate).utcOffset(TIME_ZONE).format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 150,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => getPromoCode(params.row.promoCodeId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Notification">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setPromoCodeId(params.row.promoCodeId);
                  setNotificationDialogs(true);
                }}
              >
                <Notifications style={{ color: "#F16647" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteCategory(params.row.promoCodeId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={
          isLoadingPromoCodeDetails || isLoadingDelete || isLoadingNotification
        }
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.promoCodeId}
        addButton={true}
      />
      <NotificationModal
        addNotification={addPromoCodeNotification}
        id={promoCodeId}
        emptyField={emptyField}
      />
    </Fragment>
  );
};

export default PromoCodeDataTabelContainer;
