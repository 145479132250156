import React, { useState } from "react";
import CssContext from "./CssContext";

const CssState = (props) => {
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const [mobileSidebarOpenAndClose, setMobileSidebarOpenAndClose] =
    useState(false);

  const [darkMode, setDarkMode] = useState(false);
  const [isDashboardRedirect, setIsDashboardRedirect] = useState({
    isShow: false,
    redirect: "",
  });
  return (
    <CssContext.Provider
      value={{
        mobileSidebar,
        setMobileSidebar,
        mobileSidebarOpenAndClose,
        setMobileSidebarOpenAndClose,
        darkMode,
        setDarkMode,
        isDashboardRedirect,
        setIsDashboardRedirect,
      }}
    >
      {props.children}
    </CssContext.Provider>
  );
};

export default CssState;
