import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  DEALOFTHEDAY_,
  DEALOFTHEDAY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import DealOfTheDayContext from "../../../../context/DealOfTheDayContext/DealOfTheDayContext";
import { get_Deal_Of_The_Day } from "../../../../Apis/dealOfTheDayApi/DealOfTheDayApi";
import DealOfTheDayDataTabelContainer from "./DealOfTheDayDataTabelContainer";

const DealOfTheDayList = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,
  } = useContext(DealOfTheDayContext);

  const history = useNavigate();

  /**
   * get banner slider List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    // search: { dealOfTheDay: 2 },
  };

  if (filter) {
    paramsObj["search"] = { productName: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getDealOfTheDay", paramsObj],
    async () => await get_Deal_Of_The_Day(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  return (
    <>
      <Breadcrumb
        name={DEALOFTHEDAY_}
        slace={SLACE}
        name1={DEALOFTHEDAY_LIST}
      />

      <DealOfTheDayDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
      />
    </>
  );
};

export default DealOfTheDayList;
