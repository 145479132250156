import axios from "axios";
import {
  CHECK_SLUG_BANNER_SLIDER,
  CREATE_BANNER_SLIDER,
  DELETE_BANNER_SLIDER,
  EDIT_BANNER_SLIDER,
  ENABLE_DISABLE_BANNER_SLIDER,
  GET_BANNER_SLIDER,
  GET_BANNER_SLIDER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";
import { get_Products } from "../productsApi/ProductsApi";
import { get_Category_Master } from "../categoryApi/CategoryApi";
import { BANNER_SLIDER_TYPE, FileType } from "../../constants";

/**
 * get banner slider list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Banner_Slider = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_BANNER_SLIDER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add banner slider api call
 * @param {form data} formData
 * @param {banner slider list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Banner_Slider = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_BANNER_SLIDER}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      if (formData.type === 1) {
        history(`${REDIRECT_PATH.SLIDER}`);
      } else {
        history(`${REDIRECT_PATH.BANNER}`);
      }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable banner slider api call
 * @param {form data as isActive & sliderBannerId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Banner_Slider = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_BANNER_SLIDER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { sliderBannerId: formData.sliderBannerId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details banner slider
 * @param {*} sliderBannerId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Banner_Slider = (
  sliderBannerId,
  history,
  setFormData,
  setAspect,
  setFileType,
  setSelectedType = null
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_BANNER_SLIDER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { sliderBannerId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = {
        showAll: true,
      };
      let responseProduct = await get_Products(paramObj);
      [responseProduct] = responseProduct?.payload?.data?.filter(
        (elem) => elem?.productId === data?.productId
      );
      let responseCategory = await get_Category_Master(paramObj);
      [responseCategory] = responseCategory?.payload?.data?.filter(
        (elem) => elem?.catId === data?.catId
      );
      let [responseSlider] = BANNER_SLIDER_TYPE?.filter(
        (elem) => elem?.code === data?.type
      );
      if (data.catId) {
        setSelectedType("2");
      } else {
        setSelectedType("1");
      }
      setFormData({
        name: data ? data?.name : "",
        productId: data?.productId
          ? {
              code: responseProduct?.productId,
              name: responseProduct?.productName,
            }
          : null,
        catId: data?.catId
          ? {
              code: responseCategory.catId,
              name: responseCategory.name,
            }
          : null,
        image: data ? data?.imageUrl : "",
        caption: data?.caption || "",
        type: data ? responseSlider : "",
        startDate: data
          ? [new Date(data?.startDate), new Date(data?.endDate)]
          : "",
        endDate: data ? data?.endDate : "",
      });
      if (data?.type === BANNER_SLIDER_TYPE[0].code) {
        setAspect(2.95 / 1);
        setFileType(FileType.SLIDER);
      } else if (data?.type === BANNER_SLIDER_TYPE[1].code) {
        setAspect(1.71 / 1);
        setFileType(FileType.BANNER);
      }
      history(`${REDIRECT_PATH.SLIDER}/${sliderBannerId}`);
      return res;
    });
};

/**
 * update banner slider api call
 * @param {form data} formData
 * @param {*} sliderBannerId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Banner_Slider = (
  formData,
  sliderBannerId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_BANNER_SLIDER}`, formData, {
      headers: { Authorization: jwt_token },
      params: { sliderBannerId },
    })
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      if (formData.type === 1) {
        history(`${REDIRECT_PATH.SLIDER}`);
      } else {
        history(`${REDIRECT_PATH.BANNER}`);
      }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete banner slider api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Banner_Slider = (sliderBannerId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_BANNER_SLIDER}`, {
      headers: { Authorization: jwt_token },
      params: { sliderBannerId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug banner slider api call
 * @param {form data} formData
 * @param {*} catId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_BANNER_SLIDER}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
