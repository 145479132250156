import axios from "axios";
import {
  CREATE_ATTRIBUTE,
  DELETE_ATTRIBUTE,
  EDIT_ATTRIBUTE,
  ENABLE_DISABLE_ATTRIBUTE,
  GET_ATTRIBUTE,
  GET_ATTRIBUTE_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { ATTRIBUTE_TYPE_DROP } from "../../constants";

/**
 * get attribute list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Attribute = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ATTRIBUTE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add attribute api call
 * @param {form data} formData
 * @returns
 */

export const Create_Attribute = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_ATTRIBUTE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable attribute api call
 * @param {form data as isActive & userId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Attribute = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_ATTRIBUTE}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { userId: formData.userId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details attribute
 * @param {*} attributeId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Attribute = (attributeId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_ATTRIBUTE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { attributeId },
    })
    .then((res) => {
      let data = res.data.payload.data;
      let newArr = ATTRIBUTE_TYPE_DROP;
      setFormData({
        value: data?.value || "",
        attributeType:
          newArr.find((elem) => elem.code === data?.attributeType) || null,
        attributeMasterId: data?.attributeMasterId
          ? { code: data?.attributeMasterId, name: data?.attributeMaster?.name }
          : null,
        order: data?.order || "",
      });
      setdialogs(true);
    });
};

/**
 * update attribute api call
 * @param {form data} formData
 * @param {*} attributeId
 * @returns
 */

export const Put_Update_Attribute = (formData, attributeId) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_ATTRIBUTE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { attributeId },
    })
    .then((message) => {
      return message;
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete attribute api call
 * @param {form data} formData
 * @param {*} attributeId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Attribute = (attributeId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_ATTRIBUTE}`, {
      headers: { Authorization: jwt_token },
      params: { attributeId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
