import axios from "axios";
import {
  CREATE_DELIVERY_SLOT,
  DELETE_DELIVERY_SLOT,
  EDIT_DELIVERY_SLOT,
  ENABLE_DISABLE_DELIVERY_SLOT,
  GET_DELIVERY_SLOT,
  GET_DELIVERY_SLOT_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage, getLocalStringTime } from "../../utils/Helper";
import { get_Store } from "../storeApi/StoreApi";

/**
 * get delivery slot list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Delivery_Slot = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DELIVERY_SLOT}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add delivery slot api call
 * @param {form data} formData
 * @param {store list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyFormData} emptyFormData
 * @returns
 */

export const Create_Delivery_Slot = (
  formData,
  refetch,
  setdialogs,
  emptyFormData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_DELIVERY_SLOT}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyFormData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable delivery slot api call
 * @param {form data as isActive & slotId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Delivery_Slot = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_DELIVERY_SLOT}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { slotId: formData.slotId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details delivery slot
 * @param {*} slotId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Delivery_Slot = (slotId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_DELIVERY_SLOT_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { slotId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let getResponseForStoreId = await get_Store();
      getResponseForStoreId = getResponseForStoreId?.payload?.data?.find(
        (elem) => elem.storeId === data?.storeId
      );
      await setFormData({
        slotName: data ? data?.slotName : "",
        storeId: data
          ? {
              code: getResponseForStoreId.storeId,
              name: getResponseForStoreId.name,
            }
          : "",
        startTime: data ? getLocalStringTime(data?.startTime) : "",
        endTime: data ? getLocalStringTime(data?.endTime) : "",
        order: data ? data?.order : "",
      });
      setdialogs(true);
    });
};

/**
 * update delivery slot api call
 * @param {form data} formData
 * @param {*} slotId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyFormData} emptyFormData
 * @returns
 */

export const Put_Update_Delivery_Slot = (
  formData,
  slotId,
  refetch,
  setdialogs,
  emptyFormData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_DELIVERY_SLOT}`, formData, {
      headers: { Authorization: jwt_token },
      params: { slotId },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyFormData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete delivery slot api call
 * @param {form data} formData
 * @param {*} slotId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Delivery_Slot = (slotId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_DELIVERY_SLOT}`, {
      headers: { Authorization: jwt_token },
      params: { slotId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
