import axios from "axios";
import {
  CHECK_SLUG_STORE,
  CREATE_STORE,
  DELETE_STORE,
  EDIT_STORE,
  ENABLE_DISABLE_STORE,
  GET_STORE,
  GET_STORE_DETAILS,
  GET_STORE_FOR_ADD_UPDATE,
  GET_STORE_WALLET_HISTORY,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { TYPE } from "../../constants";

/**
 * get store list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Store = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_STORE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get store wallet history list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Store_Wallet_History = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_STORE_WALLET_HISTORY}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * get store list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Store_For_Add_Update = (paramsObj, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_STORE_FOR_ADD_UPDATE}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => {
      let [valueOfType] = TYPE.filter((elem) => elem.code === elem.type);
      setFormData((prev) => {
        return {
          ...prev,
          stocks: res.data?.payload?.data?.map((elem) => {
            return {
              ...prev.stocks,
              storeId: elem.storeId,
              storeName: elem.name,
              qty: 0,
              minStock: elem?.productStocks[0]?.minStock,
              maxStock: elem?.productStocks[0]?.maxStock,
              type: valueOfType ?? null,
              totalQty: elem?.productStocks[0]?.qty ?? 0,
              note: elem?.productStocks[0]?.note ?? null,
              copyTotalQty: elem?.productStocks[0]?.qty ?? 0,
            };
          }),
        };
      });
      return res.data;
    });
};

/**
 * add store api call
 * @param {form data} formData
 * @param {store list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyFormData} emptyFormData
 * @returns
 */

export const Create_Store = (formData, refetch, setdialogs, emptyFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(`${process.env.REACT_APP_API_URL}${CREATE_STORE}`, formData, {
      headers: { Authorization: jwt_token },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyFormData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable store api call
 * @param {form data as isActive & storeId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Store = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_STORE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { storeId: formData.storeId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details store
 * @param {*} storeId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Store = (storeId, setFormData, setdialogs) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_STORE_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { storeId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let userData = [];
      data.assignUsers?.forEach((elem) => {
        if (elem.userDetail?.name !== undefined) {
          userData.push({ code: elem?.userId, name: elem?.userDetail?.name });
        }
        return;
      });
      await setFormData({
        name: data ? data?.name : "",
        address: data ? data?.address : "",
        assignUsers: userData,
        latitude: data ? data?.latitude : "",
        longitude: data ? data?.longitude : "",
      });
      setdialogs(true);
    });
};

/**
 * update store api call
 * @param {form data} formData
 * @param {*} storeId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyFormData} emptyFormData
 * @returns
 */

export const Put_Update_Store = (
  formData,
  storeId,
  refetch,
  setdialogs,
  emptyFormData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_STORE}`, formData, {
      headers: { Authorization: jwt_token },
      params: { storeId },
    })
    .then((message) => {
      refetch();
      setdialogs(false);
      emptyFormData();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete store api call
 * @param {form data} formData
 * @param {*} storeId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Store = (storeId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_STORE}`, {
      headers: { Authorization: jwt_token },
      params: { storeId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug store api call
 * @param {form data} formData
 * @param {*} storeId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_STORE}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {
      // if (message.status === 200) {
      //   ToastMessage(message.status, message.data.message);
      // }
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
