import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import StoreDataTabelContainer from "./StoreDataTabelContainer";
import StoreCreateFormPopup from "./StoreCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  STORE,
  SLACE,
  STORE_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateStore, UpdateStore } from "../../../../constants";
import StoreContext from "../../../../context/StoreContext/StoreContext";
import { get_Store } from "../../../../Apis/storeApi/StoreApi";
import { BackButton } from "../../../../reuseableComponents/buttons";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const Store = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //store edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
  } = useContext(StoreContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const emptyFormData = () => {
    setFormData({
      name: "",
      address: "",
      latitude: "",
      longitude: "",
      assignUsers: "",
    });
  };

  const history = useNavigate();

  /**
   * get store List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getStore", paramsObj],
    async () => await get_Store(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup store
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyFormData();
    } else {
      setdialogs(condition);
      emptyFormData();
    }
  };

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };
  return (
    <>
      <Breadcrumb name={STORE} slace={SLACE} name1={STORE_LIST} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <StoreDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateStore : UpdateStore}
        Component={
          dialog && (
            <StoreCreateFormPopup
              refetch={refetch}
              emptyFormData={emptyFormData}
            />
          )
        }
      />
    </>
  );
};

export default Store;
