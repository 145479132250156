import axios from "axios";
import {
  CHECK_SLUG_PRODUCTS,
  CREATE_PRODUCTS_STOCK,
  DELETE_PRODUCTS_STOCK,
  EDIT_PRODUCTS_STOCK,
  ENABLE_DISABLE_PRODUCTS_STOCK,
  EXPORT_EXCEL_PRODUCTS_STOCK,
  GET_PRODUCTS_STOCK,
  GET_PRODUCTS_STOCK_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";
import { get_Products } from "../productsApi/ProductsApi";
import { get_Store } from "../storeApi/StoreApi";

/**
 * get products stock list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Products_Stock = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_STOCK}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add products stock api call
 * @param {form data} formData
 * @param {products stock list function} refetch
 * @param {modal close} setdialogs
 * @param {setFormData} setFormData
 * @returns
 */

export const Create_Products_Stock = (formData, history, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_PRODUCTS_STOCK}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      history(REDIRECT_PATH.PRODUCTS_STOCK);
      setFormData({ productId: "", stocks: [] });
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable products stock api call
 * @param {form data as isActive & stockId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Products_Stock = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_PRODUCTS_STOCK}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { stockId: formData.stockId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details products stock
 * @param {*} stockId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Products_Stock = (stockId, history, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_STOCK_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { stockId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      let paramObj = { showAll: true };
      let responseProductDrop = await get_Products(paramObj);
      let responseStoreDrop = await get_Store(paramObj);

      [responseProductDrop] = responseProductDrop?.payload?.data?.filter(
        (elemProduct) => elemProduct.productId === data.productId
      );
      [responseStoreDrop] = responseStoreDrop?.payload?.data?.filter(
        (elemStore) => elemStore.storeId === data.storeId
      );
      setFormData({
        productId: data
          ? {
              code: responseProductDrop?.productId,
              name: responseProductDrop?.productName,
            }
          : "",
        storeId: data
          ? {
              code: responseStoreDrop?.storeId,
              name: responseStoreDrop?.name,
            }
          : "",
        qty: data ? data?.qty : "",
        note: data ? data?.note : "",
        minStock: data ? data?.minStock : "",
        maxStock: data ? data?.maxStock : "",
      });
      history(`${REDIRECT_PATH.PRODUCTS_STOCK}/${stockId}`);
    });
};

/**
 * update products stock api call
 * @param {form data} formData
 * @param {*} stockId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Put_Update_Products_Stock = (
  formData,
  stockId,
  history,
  setFormData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(`${process.env.REACT_APP_API_URL}${EDIT_PRODUCTS_STOCK}`, formData, {
      headers: { Authorization: jwt_token },
      params: { stockId },
    })
    .then((message) => {
      history(REDIRECT_PATH.PRODUCTS_STOCK);
      setFormData({ productId: "", stocks: [] });
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete products stock api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Products_Stock = (stockId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_PRODUCTS_STOCK}`, {
      headers: { Authorization: jwt_token },
      params: { stockId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * download excel products stock api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Export_Excel_Products_Stock = (paramObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${EXPORT_EXCEL_PRODUCTS_STOCK}`, {
      headers: { Authorization: jwt_token, Accept: "text/csv" },
      params: paramObj,
      responseType: "blob",
    })
    .then((response) => {
      const data = response.data;
      const downloadLink = document.createElement("a");
      const blobUrl = window.URL.createObjectURL(data);
      downloadLink.href = blobUrl;
      downloadLink.download = "stock.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      window.URL.revokeObjectURL(blobUrl);
      ToastMessage(response.status, response);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug products stock api call
 * @param {form data} formData
 * @param {*} stockId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_PRODUCTS}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
