import { createSlice } from "@reduxjs/toolkit";

export const headerDropdownSlice = createSlice({
  initialState: false,
  name: "headerDropdown",
  reducers: {
    dopdownOpen: (state) => {
      return !state;
    },
    dropdownClose: (state) => {
      return false;
    },
  },
});

export const { dopdownOpen, dropdownClose } = headerDropdownSlice.actions;
export default headerDropdownSlice.reducer;
