import React, { useContext } from "react";
import { Col } from "react-bootstrap";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import CssContext from "../../../context/css_context/CssContext/CssContext";

const DashboardItems = (props) => {
  const { elem } = props;
  const { setIsDashboardRedirect } = useContext(CssContext);

  return (
    <Col md="3 mb-4">
      <Link
        to={elem.redirect}
        onClick={() =>
          setIsDashboardRedirect({
            isShow: true,
            redirect: "/",
          })
        }
      >
        <div className="col flex flex-column justify-content-center text-center align-items-center border-1 surface-border py-5 px-4 border-round">
          <span className="text-900 font-bold mt-4 mb-1">{elem.name}</span>
          <span className="text-500">
            {elem.total ? (
              `${elem.count} | ` + elem.total.toLocaleString()
            ) : (
              <CountUp end={elem.count} />
            )}
          </span>
        </div>
      </Link>
    </Col>
  );
};

export default DashboardItems;
