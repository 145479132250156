import React, { useState } from "react";
import GuestHouseMasterContext from "./GuestHouseMasterContext";

const GuestHouseMasterState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**organization edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [guestHouseId, setGuestHouseId] = useState(null);
  const [formData, setFormData] = useState({
    name: null,
    address: null,
    assignAdminIds: [],
  });

  return (
    <GuestHouseMasterContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        guestHouseId,
        setGuestHouseId,
        formData,
        setFormData,
      }}
    >
      {props.children}
    </GuestHouseMasterContext.Provider>
  );
};

export default GuestHouseMasterState;
