import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import AttributeDataTabelContainer from "./AttributeDataTabelContainer";
import AttributeCreateFormPopup from "./AttributeCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  ATTRIBUTE_CHID_,
  ATTRIBUTE_List_,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateAttribute_, UpdateAttribute_ } from "../../../../constants";
import AttributeContext from "../../../../context/AttributeContext/AttributeContext";
import { get_Attribute } from "../../../../Apis/attributeApi/AttributeApi";

const Attribute = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //attribute edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(AttributeContext);

  const history = useNavigate();

  /**
   * get attribute List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { value: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getAttribute", paramsObj],
    async () => await get_Attribute(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    },
    {}
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup attribute
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      setFormData({
        value: "",
        attributeType: null,
        attributeMasterId: null,
        order: "",
      });
      setErrors({});
    } else {
      setdialogs(condition);
      setFormData({
        value: "",
        attributeType: null,
        attributeMasterId: null,
        order: "",
      });
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb
        name={ATTRIBUTE_CHID_}
        slace={SLACE}
        name1={ATTRIBUTE_List_}
      />
      <AttributeDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateAttribute_ : UpdateAttribute_}
        Component={<AttributeCreateFormPopup refetch={refetch} />}
      />
    </>
  );
};

export default Attribute;
