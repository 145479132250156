import axios from "axios";
import {
  CHECK_SLUG_PRODUCTS_TAG_MASTER,
  CREATE_PRODUCTS_TAG_MASTER,
  DELETE_PRODUCTS_TAG_MASTER,
  EDIT_PRODUCTS_TAG_MASTER,
  ENABLE_DISABLE_PRODUCTS_TAG_MASTER,
  GET_PRODUCTS_TAG_MASTER,
  GET_PRODUCTS_TAG_MASTER_DETAILS,
} from "../../constants/ApiConstant";
import { ToastMessage } from "../../utils/Helper";
import { REDIRECT_PATH } from "../../Routes";

/**
 * get product tag master list data
 * @param {pagination params} paramsObj
 * @returns data response
 */

export const get_Product_Tag_Master = (paramsObj) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_TAG_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: paramsObj,
    })
    .then((res) => res.data);
};

/**
 * add product tag  master api call
 * @param {form data} formData
 * @param {product tag master list function} refetch
 * @param {modal close} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Create_Product_Tag_Master = (formData, history, emptyData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .post(
      `${process.env.REACT_APP_API_URL}${CREATE_PRODUCTS_TAG_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
      }
    )
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.PRODUCTS_TAG}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 *enable - disable product tag  master api call
 * @param {form data as isActive & tagId} formData
 * @param {list api call function} refetch
 * @returns true & false
 */

export const Enable_Disable_Product_Tag_Master = (formData, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${ENABLE_DISABLE_PRODUCTS_TAG_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { tagId: formData.tagId },
      }
    )
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      if (error.response) {
        const { status, message } = error?.response?.data || error;
        ToastMessage(status, message);
      }
    });
};

/**
 * get details product tag  master
 * @param {*} tagId
 * @param {form data func} setFormData
 * @param {modal func} setdialogs
 * @returns
 */
export const Get_Details_Product_Tag_Master = (tagId, history, setFormData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${GET_PRODUCTS_TAG_MASTER_DETAILS}`, {
      headers: { Authorization: jwt_token },
      params: { tagId },
    })
    .then(async (res) => {
      let data = res.data.payload.data;
      setFormData({
        tagName: data ? data?.tagName : null,
        tagColor: data ? data?.tagColor : null,
      });
      history(`${REDIRECT_PATH.PRODUCTS_TAG}/${tagId}`);
    });
};

/**
 * update product tag  master api call
 * @param {form data} formData
 * @param {*} tagId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {emptyData} emptyData
 * @returns
 */

export const Put_Update_Product_Tag_Master = (
  formData,
  tagId,
  history,
  emptyData
) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .put(
      `${process.env.REACT_APP_API_URL}${EDIT_PRODUCTS_TAG_MASTER}`,
      formData,
      {
        headers: { Authorization: jwt_token },
        params: { tagId },
      }
    )
    .then((message) => {
      emptyData();
      ToastMessage(message.status, message.data.message);
      history(`${REDIRECT_PATH.PRODUCTS_TAG}`);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * delete product tag master api call
 * @param {form data} formData
 * @param {*} brandId
 * @param {list api call } refetch
 * @returns
 */

export const Delete_Product_Tag_Master = (tagId, refetch) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .delete(`${process.env.REACT_APP_API_URL}${DELETE_PRODUCTS_TAG_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: { tagId },
    })
    .then((message) => {
      refetch();
      ToastMessage(message.status, message.data.message);
    })
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};

/**
 * check slug product tag  master api call
 * @param {form data} formData
 * @param {*} tagId
 * @param {list api call } refetch
 * @param {modal func} setdialogs
 * @param {form data func} setFormData
 * @returns
 */

export const Get_Check_Slug_Url = (formData) => {
  const jwt_token = localStorage.getItem("authToken");
  return axios
    .get(`${process.env.REACT_APP_API_URL}${CHECK_SLUG_PRODUCTS_TAG_MASTER}`, {
      headers: { Authorization: jwt_token },
      params: formData,
    })
    .then((message) => {})
    .catch((error) => {
      const { status, message } = error?.response?.data || error;
      ToastMessage(status, message);
    });
};
