import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import {
  Delete_Delivery_Off_Day,
  Get_Details_Delivery_Off_Day,
} from "../../../../Apis/deliveryOffDayApi/DeliveryOffDayApi";
import DeliveryOffDayContext from "../../../../context/DeliveryOffDayContext/DeliveryOffDayContext";

const DeliveryOffDayDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(DeliveryOffDayContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get delivery off day Details
   */
  const {
    mutate: deliveryOffDayDetails,
    isLoading: isLoadingDeliveryOffDayDetails,
  } = useMutation((dayOffId) =>
    Get_Details_Delivery_Off_Day(dayOffId, setFormData, setdialogs)
  );

  const GetDeliveryOffDayDetails = (dayOffId) => {
    deliveryOffDayDetails(dayOffId);
    setId(dayOffId);
    setIsEdit(false);
  };

  /**
   * delete delivery off day
   */
  const { mutate: deliveryOffDayDelete, isLoading: isLoadingDelete } =
    useMutation((dayOffId) => Delete_Delivery_Off_Day(dayOffId, refetch));

  const DeleteDeliveryOffDay = async (dayOffId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      deliveryOffDayDelete(dayOffId);
    }
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "slotOffType",
      headerName: "Slot Of Type",
      renderCell: (params) =>
        params.row.slotOffType === 2 ? "Specific" : "All",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "offDate",
      headerName: "Off Date",
      renderCell: (params) =>
        params.row.offDate
          ? moment(params.row.offDate)
              .utcOffset(TIME_ZONE)
              .format("DD MMM, YYYY hh:mm")
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "storeName",
      headerName: "Store Name",
      renderCell: (params) =>
        params.row.storeDetail ? params.row.storeDetail?.name : "-",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetDeliveryOffDayDetails(params.row.dayOffId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteDeliveryOffDay(params.row.dayOffId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingDeliveryOffDayDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.dayOffId}
        addButton={true}
        search={false}
      />
    </Fragment>
  );
};

export default DeliveryOffDayDataTabelContainer;
