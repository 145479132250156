import React, { useContext, useState } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  CATEGORY_,
  CATEGORY_LIST,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import CategoryContext from "../../../../context/CategoryContext/CategoryContext";
import { get_Category_Master } from "../../../../Apis/categoryApi/CategoryApi";
import CategoryMasterDataTabelContainer from "./CategoryMasterDataTabelContainer";
import { BackButton } from "../../../../reuseableComponents/buttons";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const CategoryMaster = () => {
  const {
    //pagination state variables
    setRecord,
    sortOrder,
    sortBy,
    filter,
    pCatId,
    pagination,
    setPagination,
  } = useContext(CategoryContext);
  const { isDashboardRedirect, setIsDashboardRedirect } =
    useContext(CssContext);

  const history = useNavigate();

  /**
   * get category List
   */

  let paramsObj = {
    pageNumber: pagination.page + 1,
    recordsPerPage: pagination.pageCount,
    sortOrder,
    sortBy,
  };
  if (filter?.length) {
    paramsObj["search"] = { name: filter };
  }
  if (pCatId !== "") {
    paramsObj["search"] = "";
    paramsObj["pCatId"] = pCatId;
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getCategoryMaster", paramsObj],
    async () => await get_Category_Master(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const prev = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: "",
    });
  };

  return (
    <>
      <Breadcrumb name={CATEGORY_} slace={SLACE} name1={CATEGORY_LIST} />
      {isDashboardRedirect?.isShow && (
        <BackButton
          history={history}
          path={isDashboardRedirect.redirect}
          otherFunc={prev}
        />
      )}
      <CategoryMasterDataTabelContainer
        isLoading={isLoading}
        data={data}
        refetch={refetch}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default CategoryMaster;
