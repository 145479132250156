import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Login_ } from "../Apis/AuthApi";
import { NEW_PASSWORD } from "../constants";
import "../assets/css/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { IsLoadingResponse } from "../utils/Helper";
import { Form } from "reactstrap";
import { useMutation } from "react-query";
import { REDIRECT_PATH } from "../Routes";
import { Button } from "primereact/button";
import { Password } from "primereact/password";

const ResetPassword = () => {
  const history = useNavigate();

  const [formData, setformData] = useState({
    password: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const LoginToWebsite = async (e) => {
    e.preventDefault();
    if (formData.phone.length !== 0 && formData.password.length !== 0) {
      Login(formData);
    }
  };

  const { mutate: Login, isLoading: LoginLoading } = useMutation((formData) =>
    Login_(formData, history)
  );

  return (
    <>
      <IsLoadingResponse isLoading={LoginLoading} />
      <div className="login_background">
        <div className="login_main">
          <div className="login_center_container">
            <div className="login_content_container">
              <div>
                <div className="header">
                  <div>{NEW_PASSWORD}</div>
                  <span>Enter your new password</span>
                </div>
                <Form onSubmit={(e) => LoginToWebsite(e)}>
                  <div className="flex flex-column">
                    <span className="p-input-icon-left w-full mb-3">
                      <i className="pi pi-lock z-2"></i>
                      <Password
                        value={formData.password}
                        name="password"
                        onChange={(e) => handleChange(e)}
                        toggleMask
                        className="p-inputtext p-component p-password-input w-full md:w-25rem"
                        placeholder="Password"
                        style={{
                          width: "100%",
                          height: "44px",
                          padding: 0,
                          border: "none",
                        }}
                      />
                    </span>
                    <span className="p-input-icon-left w-full mb-3">
                      <i className="pi pi-lock z-2"></i>
                      <Password
                        value={formData.confirmPassword}
                        name="confirmPassword"
                        onChange={(e) => handleChange(e)}
                        toggleMask
                        className="p-inputtext p-component p-password-input w-full md:w-25rem"
                        placeholder="Confirm Password"
                        style={{
                          width: "100%",
                          height: "44px",
                          padding: 0,
                          border: "none",
                        }}
                      />
                    </span>
                  </div>
                  <div className="flex justify-content-between flex-wrap gap-2 ">
                    <Button
                      label="Cancel"
                      outlined
                      className="p-button p-component p-button-outlined flex-auto"
                      style={{ height: "44px" }}
                      onClick={() => history(REDIRECT_PATH.AUTH)}
                    />
                    <Button
                      label="Submit"
                      className="p-button p-component flex-auto "
                      style={{ height: "44px" }}
                      type="button"
                      onClick={() => history(REDIRECT_PATH.RESET_PASSWORD)}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
