import { Delete } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import InquiryContext from "../../../../context/InquiryContext/InquiryContext";
import { Delete_Inquiry } from "../../../../Apis/inquiryApi/InquiryApi";
import { Eye } from "react-feather";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";

const InquiryDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(InquiryContext);
  const { isLoading, data, refetch } = props;
  const history = useNavigate();

  const InquiryDetails = (inquiryId) => {
    history(`${REDIRECT_PATH.INQUIRY_}/${inquiryId}/details`);
  };

  /**
   * delete inquiry
   */
  const { mutate: InquiryDelete, isLoading: isLoadingDelete } = useMutation(
    (inquiryId) => Delete_Inquiry(inquiryId, refetch)
  );

  const DeleteInquiry = async (inquiryId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      InquiryDelete(inquiryId);
    }
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "dialCode",
      headerName: "Number",
      renderCell: (params) =>
        params.row.dialCode && params.row.phone
          ? `${params.row.dialCode} ${params.row.phone}`
          : "--",
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "subject",
      headerName: "Subject",
      renderCell: (params) => (params.row.subject ? params.row.subject : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "message",
      headerName: "Message",
      renderCell: (params) => (params.row.message ? params.row.message : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Show Inquiry">
              <IconButton
                aria-label="showInquiry"
                onClick={() => InquiryDetails(params.row.inquiryId)}
              >
                <Eye style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteInquiry(params.row.inquiryId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingDelete} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.inquiryId}
        addButton={false}
      />
    </Fragment>
  );
};

export default InquiryDataTabelContainer;
