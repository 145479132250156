import moment from "moment";
import React, { Fragment, useContext, useState } from "react";
import { useQuery } from "react-query";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  STORE,
  STORE_WALLET_HISTORY_ITEM,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { BackButton } from "../../../../reuseableComponents/buttons";
import StoreContext from "../../../../context/StoreContext/StoreContext";
import { get_Store_Wallet_History } from "../../../../Apis/storeApi/StoreApi";

const StoreWalletHistoryDataTabelContainer = () => {
  const { filter, setFilter } = useContext(StoreContext);
  const history = useNavigate();

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [dateValue, setDateValue] = useState(null);
  const { storeId } = useParams();

  /**
   * get rider wallet history List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
    storeId,
  };

  const handleChangeDate = (e) => {
    const { value } = e.target;
    setDateValue(value);
  };

  if (dateValue !== null) {
    paramsObj["search"] = {
      selectedDate: moment(dateValue).format(),
    };
  }

  let {
    data: storeWalletHistoryData,
    isLoading: storeWalletHistoryLoader,
    error: storeWalletHistoryError,
  } = useQuery(
    ["getStoreWalletHistory", paramsObj],
    async () => await get_Store_Wallet_History(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  if (storeWalletHistoryError) {
    const { status, message } =
      storeWalletHistoryError?.response?.data || storeWalletHistoryError;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.RIDER}/add`);
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "type",
      headerName: "Type",
      renderCell: (params) => (params.row.type === 1 ? "Credit" : "Debit"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "debitAmount",
      headerName: "Debit Amount",
      renderCell: (params) =>
        params.row.debitAmount ? params.row.debitAmount : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "creditAmount",
      headerName: "Credit Amount",
      renderCell: (params) =>
        params.row?.creditAmount ? params.row?.creditAmount : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "currentAmount",
      headerName: "Current Amount",
      renderCell: (params) =>
        params.row?.currentAmount ? params.row?.currentAmount : "--",
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "refNo",
      headerName: "Ref No.",
      renderCell: (params) => (params.row?.refNo ? params.row?.refNo : "--"),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "note",
      headerName: "Note",
      renderCell: (params) => (params.row?.note ? params.row?.note : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt).format("DD MMM, YYYY hh:mm"),
    },
  ];
  return (
    <Fragment>
      <Breadcrumb
        name={STORE}
        slace={SLACE}
        name1={STORE_WALLET_HISTORY_ITEM}
      />
      <BackButton history={history} path={REDIRECT_PATH.STORE} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={storeWalletHistoryData}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={storeWalletHistoryLoader}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.storeWalletId}
        addButton={false}
        search={false}
        dateFilter={true}
        date={dateValue}
        handleChangeDate={handleChangeDate}
        isRange={false}
        dateFilterPlaceholder={"Date"}
      />
    </Fragment>
  );
};

export default StoreWalletHistoryDataTabelContainer;
