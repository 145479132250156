import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { Role } from "../constants";

const PrivateRoute = () => {
  const removeLocalstorage = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("role");
  };
  return localStorage.getItem("authToken") &&
    +localStorage.getItem("role") === Role.SuperAdmin ? (
    <>
      <Outlet />
    </>
  ) : localStorage.getItem("authToken") &&
    +localStorage.getItem("role") === Role.Store_Admin ? (
    <>
      <Outlet />
      {/* <Navigate to="/" /> */}
    </>
  ) : localStorage.getItem("authToken") &&
    +localStorage.getItem("role") === Role.Delivery_Boy ? (
    <>
      {removeLocalstorage()}
      <Navigate to="/auth" />
      {/* <Navigate to="/" /> */}
    </>
  ) : (
    <>
      {removeLocalstorage()}
      <Navigate to="/auth" />
    </>
  );
};

export default PrivateRoute;
