import React, { useState } from "react";
import "../assets/css/filterSidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { filterSidebarClose } from "../redux/slices/filter";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Col, Row } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

const FilterSidebar = () => {
  /**redux */
  const filterSidebar = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const [ingredient, setIngredient] = useState("");
  const [dates, setDates] = useState(null);
  const [checked, setChecked] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [
    { name: "Australia", code: "AU" },
    { name: "Brazil", code: "BR" },
    { name: "China", code: "CN" },
    { name: "Egypt", code: "EG" },
    { name: "France", code: "FR" },
    { name: "Germany", code: "DE" },
    { name: "India", code: "IN" },
    { name: "Japan", code: "JP" },
    { name: "Spain", code: "ES" },
    { name: "United States", code: "US" },
  ];

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "18px" }}
          />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div>{option.name}</div>
      </div>
    );
  };

  return (
    <>
      <div
        className={filterSidebar ? "layout-mask-filter modal-in-filter":""}
        onClick={() => dispatch(filterSidebarClose())}
      ></div>
      <div
        className={filterSidebar ? "filter-sidebar" : "filter-sidebar-close"}
      >
        <div
          onClick={() => dispatch(filterSidebarClose())}
          className="filter-btn-close d-flex  justify-content-between  align-items-center "
        >
          <h5
            style={{ color: "#6366f1", textTransform: "uppercase" }}
            className="m-0 "
          >
            filters
          </h5>
          <span>X</span>
        </div>
        <div className="p-4 content-filter">
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Division</h6>
            <div className="flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ingredient1"
                  name="pizza"
                  value="Cheese"
                  onChange={(e) => setIngredient(e.value)}
                  checked={ingredient === "Cheese"}
                />
                <label htmlFor="ingredient1" className="ml-2">
                  All
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ingredient2"
                  name="pizza"
                  value="Mushroom"
                  onChange={(e) => setIngredient(e.value)}
                  checked={ingredient === "Mushroom"}
                />
                <label htmlFor="ingredient2" className="ml-2">
                  A
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ingredient3"
                  name="pizza"
                  value="Pepper"
                  onChange={(e) => setIngredient(e.value)}
                  checked={ingredient === "Pepper"}
                />
                <label htmlFor="ingredient3" className="ml-2">
                  B
                </label>
              </div>
              <div className="flex align-items-center">
                <RadioButton
                  inputId="ingredient4"
                  name="pizza"
                  value="Onion"
                  onChange={(e) => setIngredient(e.value)}
                  checked={ingredient === "Onion"}
                />
                <label htmlFor="ingredient4" className="ml-2">
                  C
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Room Number</h6>
            <Row>
              <Col md="12">
                <InputText
                  type="text"
                  className="w-full p-inputtext-sm"
                  placeholder="Room number"
                />
              </Col>
            </Row>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">check in & check out</h6>
            <Row>
              <Col md="12">
                <Calendar
                  value={dates}
                  onChange={(e) => setDates(e.value)}
                  selectionMode="range"
                  // readOnlyInput
                  className="w-full p-inputtext-sm"
                  touchUI
                />
              </Col>
            </Row>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Late & status</h6>
            <div className="card flex flex-wrap justify-content-center gap-3">
              <div className="flex align-items-center">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                  inputId="ingredient1"
                ></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">
                  Is Late?
                </label>
              </div>
              <div className="flex flex-wrap gap-3">
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient1"
                    name="pizza"
                    value="Cheese"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Cheese"}
                  />
                  <label htmlFor="ingredient1" className="ml-2">
                    All
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient2"
                    name="pizza"
                    value="Mushroom"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Mushroom"}
                  />
                  <label htmlFor="ingredient2" className="ml-2">
                    Current Booking
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient3"
                    name="pizza"
                    value="Pepper"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Pepper"}
                  />
                  <label htmlFor="ingredient3" className="ml-2">
                    Room Allocated
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient4"
                    name="pizza"
                    value="Onion"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Onion"}
                  />
                  <label htmlFor="ingredient4" className="ml-2">
                    Today Check In
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient4"
                    name="pizza"
                    value="Onion"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Onion"}
                  />
                  <label htmlFor="ingredient4" className="ml-2">
                    Today Check Out
                  </label>
                </div>
                <div className="flex align-items-center">
                  <RadioButton
                    inputId="ingredient4"
                    name="pizza"
                    value="Onion"
                    onChange={(e) => setIngredient(e.value)}
                    checked={ingredient === "Onion"}
                  />
                  <label htmlFor="ingredient4" className="ml-2">
                    Available
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Purpose</h6>
            <div className=" flex flex-wrap gap-3">
              <div className="flex align-items-center">
                <Checkbox
                  inputId="ingredient1"
                  name="pizza"
                  value="Cheese"
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                />
                <label htmlFor="ingredient1" className="ml-2">
                  Darshan
                </label>
              </div>
              <div className="flex align-items-center">
                <Checkbox
                  onChange={(e) => setChecked(e.checked)}
                  checked={checked}
                  inputId="ingredient1"
                ></Checkbox>
                <label htmlFor="ingredient1" className="ml-2">
                  Shibir
                </label>
              </div>
            </div>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Country, State & City</h6>
            <Row>
              <Col md="6 mb-3">
                <Dropdown
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.value)}
                  options={countries}
                  optionLabel="name"
                  placeholder="Country"
                  filter
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  className="w-full"
                />
              </Col>
              <Col md="6 mb-3">
                <Dropdown
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.value)}
                  options={countries}
                  optionLabel="name"
                  placeholder="State"
                  filter
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  className="w-full"
                />
              </Col>
              <Col md="12 mb-3">
                <Dropdown
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.value)}
                  options={countries}
                  optionLabel="name"
                  placeholder="City"
                  filter
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  className="w-full"
                />
              </Col>
            </Row>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Native Place</h6>
            <Row>
              <Col md="12">
                <InputText
                  type="text"
                  className="w-full p-inputtext-sm"
                  placeholder="Native Place"
                />
              </Col>
            </Row>
          </div>
          <div className="mb-4">
            <h6 className="filter-heading mb-3 ">Payment Status & Rec. No</h6>
            <Row>
              <Col md="12 mb-3">
                <Dropdown
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.value)}
                  options={countries}
                  optionLabel="name"
                  placeholder="Country"
                  filter
                  valueTemplate={selectedCountryTemplate}
                  itemTemplate={countryOptionTemplate}
                  className="w-full"
                />
              </Col>
              <Col md="12 mb-3">
                <InputText
                  type="text"
                  className="w-full p-inputtext-sm"
                  placeholder="Recept Number"
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="p-3 position-fixed apply-btn-filter-bottom  d-flex gap-3 ">
          <div>
            <Button label="Apply" icon="pi pi-check" />
          </div>
          <div>
            <Button label="Reset" icon="pi pi-refresh" severity="warning" />
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterSidebar;
