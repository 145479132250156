import React from "react";
import { Skeleton } from "primereact/skeleton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Col, Row } from "reactstrap";

const DataTabelSkelaton = (props) => {
  const { itemArr } = props;
  const items = Array.from({ length: itemArr.length }, (v, i) => i);

  const bodyTemplate = () => {
    return <Skeleton></Skeleton>;
  };
  return (
    <div className="card">
      <DataTable value={items} className="p-datatable-striped">
        {itemArr.map((elem, i) => {
          return (
            <Column
              field="code"
              header={elem.name}
              style={{ width: "25%" }}
              body={bodyTemplate}
              key={i}
            ></Column>
          );
        })}
      </DataTable>
    </div>
  );
};

export default DataTabelSkelaton;

export const DashboardSkeleton = () => {
  return (
    <div className="grid card-div grid-nogutter border">
      <Row className="w-100 ">
        <Col md="3 mb-4">
          <div className="col flex flex-column justify-content-center text-center align-items-center border-1 surface-border py-5 px-4 border-round">
            {/* <span className="text-900 font-bold mt-4 mb-1"> */}
            {<Skeleton className="mb-2"></Skeleton>}
            {/* </span> */}
            {/* <span className="text-500"> */}
            {<Skeleton className="mb-2" width="10rem"></Skeleton>}
            {<Skeleton className="mb-2" width="5rem"></Skeleton>}
            {/* </span> */}
          </div>
        </Col>
        <Col md="3 mb-4">
          <div className="col flex flex-column justify-content-center text-center align-items-center border-1 surface-border py-5 px-4 border-round">
            {/* <span className="text-900 font-bold mt-4 mb-1"> */}
            {<Skeleton className="mb-2"></Skeleton>}
            {/* </span> */}
            {/* <span className="text-500"> */}
            {<Skeleton className="mb-2" width="10rem"></Skeleton>}
            {<Skeleton className="mb-2" width="5rem"></Skeleton>}
            {/* </span> */}
          </div>
        </Col>
        <Col md="3 mb-4">
          <div className="col flex flex-column justify-content-center text-center align-items-center border-1 surface-border py-5 px-4 border-round">
            {/* <span className="text-900 font-bold mt-4 mb-1"> */}
            {<Skeleton className="mb-2"></Skeleton>}
            {/* </span> */}
            {/* <span className="text-500"> */}
            {<Skeleton className="mb-2" width="10rem"></Skeleton>}
            {<Skeleton className="mb-2" width="5rem"></Skeleton>}
            {/* </span> */}
          </div>
        </Col>
        <Col md="3 mb-4">
          <div className="col flex flex-column justify-content-center text-center align-items-center border-1 surface-border py-5 px-4 border-round">
            {/* <span className="text-900 font-bold mt-4 mb-1"> */}
            {<Skeleton className="mb-2"></Skeleton>}
            {/* </span> */}
            {/* <span className="text-500"> */}
            {<Skeleton className="mb-2" width="10rem"></Skeleton>}
            {<Skeleton className="mb-2" width="5rem"></Skeleton>}
            {/* </span> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};
