import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";
import BannerSliderContext from "../../../../context/BannerSliderContext/BannerSliderContext";
import {
  Delete_Banner_Slider,
  Enable_Disable_Banner_Slider,
  Get_Details_Banner_Slider,
} from "../../../../Apis/bannerSliderApi/BannerSliderApi";
import CssContext from "../../../../context/css_context/CssContext/CssContext";

const BannerSliderDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    setAspect,
    setFileType,
    setSelectedType,
  } = useContext(BannerSliderContext);
  const { setIsDashboardRedirect } = useContext(CssContext);
  const { isLoading, data, refetch, type } = props;
  const history = useNavigate();

  /**
   * get banner slider Details
   */
  const {
    mutate: bannerSliderDetails,
    isLoading: isLoadingBannerSliderDetails,
  } = useMutation((sliderBannerId) =>
    Get_Details_Banner_Slider(
      sliderBannerId,
      history,
      setFormData,
      setAspect,
      setFileType,
      setSelectedType
    )
  );

  const GetBannerSlider = (sliderBannerId) => {
    bannerSliderDetails(sliderBannerId);
    setId(sliderBannerId);
    setIsEdit(false);
  };

  /**
   * delete banner slider
   */
  const { mutate: bannerSliderDelete, isLoading: isLoadingDelete } =
    useMutation((sliderBannerId) =>
      Delete_Banner_Slider(sliderBannerId, refetch)
    );

  const DeleteCategory = async (sliderBannerId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      bannerSliderDelete(sliderBannerId);
    }
  };

  /**
   * enable / disable banner slider
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Banner_Slider(formData, refetch)
  );

  const handleCategoryStatusChange = (sliderBannerId, status) => {
    let formDate = {
      sliderBannerId: sliderBannerId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.sliderBannerId === sliderBannerId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const setRedirectValue = () => {
    setIsDashboardRedirect({
      isShow: false,
      redirect: type === "banner" ? REDIRECT_PATH.BANNER : REDIRECT_PATH.SLIDER,
    });
  };

  const handleOpenandClosePopup = () => {
    history(`${REDIRECT_PATH.SLIDER}/add`);
    setRedirectValue();
  };

  const defaultColumns = [
    {
      flex: 0.05,
      minWidth: 80,
      field: "index",
      headerName: "#",
      sortable: false,
      renderCell: (params) => <div>{params.row.index}</div>,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "imageUrl",
      headerName: "Image",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <input
          type="image"
          img="true"
          src={
            params.row.imageUrl
              ? params.row.imageUrl
              : require("../../../../assets/Images/ImageNotFound.png")
          }
          alt="photo"
          style={{ height: "2rem" }}
        />
      ),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleCategoryStatusChange(
                params.row.sliderBannerId,
                params.row.isActive
              )
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      sortable: true,
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "startDate",
      headerName: "Start Date",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.startDate)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "endDate",
      headerName: "End Start",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.endDate).utcOffset(TIME_ZONE).format("DD MMM, YYYY"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => {
                  GetBannerSlider(params.row.sliderBannerId);
                  setRedirectValue();
                }}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteCategory(params.row.sliderBannerId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse
        isLoading={isLoadingBannerSliderDetails || isLoadingDelete}
      />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.sliderBannerId}
        addButton={true}
      />
    </Fragment>
  );
};

export default BannerSliderDataTabelContainer;
