import React from "react";
import {
  ORDER_SETTING_,
  ORDER_SETTING_Details,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { Col, Row } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Get_Details_Order_Setting } from "../../../../Apis/orderSettingApi/OrderSettingApi";
import { useQuery } from "react-query";
import { Button } from "primereact/button";
import { REDIRECT_PATH } from "../../../../Routes";

const OrderSettingDetails = () => {
  let { globalSettingId } = useParams();
  const history = useNavigate();
  let paramsObj = {
    globalSettingId: globalSettingId,
  };
  let { data: globalSettingDetails } = useQuery(
    ["getOrderSettingDetails", paramsObj],

    async () => await Get_Details_Order_Setting(paramsObj)
  );
  return (
    <>
      <Breadcrumb
        name={ORDER_SETTING_}
        slace={SLACE}
        name1={ORDER_SETTING_Details}
      />
      <Button
        label="Back"
        severity="danger"
        text
        icon="pi pi-arrow-left"
        style={{ marginBottom: "5px" }}
        onClick={() => history(REDIRECT_PATH.ORDER_SETTING)}
      />
      <Row>
        <Col
          md="12 mb-3"
          className="text-center "
          style={{
            background: "white",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          <h6 className="mb-2" style={{ color: "#8d8d8d" }}>
            Company Logo
          </h6>
          <img
            src={globalSettingDetails?.companyLogo}
            alt=""
            style={{ width: "100px", borderRadius: "18px" }}
          />
        </Col>
      </Row>
      <div className="container">
        <Row>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Company Name
            </h5>
            <p>{globalSettingDetails?.companyName || "-"}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Email
            </h5>
            <p>{globalSettingDetails?.email || "-"}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Mobile DialCode
            </h5>
            <p>{`${globalSettingDetails?.mobileDialCode || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Mobile
            </h5>
            <p>{`${globalSettingDetails?.mobile || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Whats App DialCode
            </h5>
            <p>{`${globalSettingDetails?.whatsappDialCode || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Whats App
            </h5>
            <p>{`${globalSettingDetails?.whatsapp || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Address
            </h5>
            <p>{`${globalSettingDetails?.address || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              City
            </h5>
            <p>{`${globalSettingDetails?.city || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              State
            </h5>
            <p>{`${globalSettingDetails?.state || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Country
            </h5>
            <p>{`${globalSettingDetails?.country || "-"}`}</p>
          </Col>{" "}
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Pincode
            </h5>
            <p>{`${globalSettingDetails?.pincode || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Android Key
            </h5>
            <p>{`${globalSettingDetails?.androidKey || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              IOS Key
            </h5>
            <p>{`${globalSettingDetails?.iosKey || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Currency
            </h5>
            <p>{`${globalSettingDetails?.currency || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Bag Fee
            </h5>
            <p>{`${globalSettingDetails?.bagFee || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              tex Id
            </h5>
            <p>{`${globalSettingDetails?.taxId || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              App Store Link
            </h5>
            <p>{`${globalSettingDetails?.appStoreLink || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Play Store Link
            </h5>
            <p>{`${globalSettingDetails?.playStoreLink || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Max Order QTY
            </h5>
            <p>{`${globalSettingDetails?.maxOrderQty || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Min Order Amount
            </h5>
            <p>{`${globalSettingDetails?.minOrderAmount || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Delivery Charge
            </h5>
            <p>{`${globalSettingDetails?.deliveryCharge || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Default TIme Zone
            </h5>
            <p>{`${globalSettingDetails?.defaultTimeZone || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Default Date Format
            </h5>
            <p>{`${globalSettingDetails?.defaultDateFormat || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Default Shorting List
            </h5>
            <p>{`${globalSettingDetails?.defaultSortListing || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Number Of Images Per Product
            </h5>
            <p>{`${globalSettingDetails?.numberOfImagesPerProduct || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Admin Percentage
            </h5>
            <p>{`${globalSettingDetails?.adminPercentage || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Order On WhatsApp
            </h5>
            <p>{`${globalSettingDetails?.orderOnWhatsapp || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Facebook
            </h5>
            <p>{`${globalSettingDetails?.facebook || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Instagram
            </h5>
            <p>{`${globalSettingDetails?.instagram || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Twitter
            </h5>
            <p>{`${globalSettingDetails?.x || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Youtube
            </h5>
            <p>{`${globalSettingDetails?.youtube || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Facebook Tag
            </h5>
            <p>{`${globalSettingDetails?.facebookTag || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Google Tag
            </h5>
            <p>{`${globalSettingDetails?.googleTag || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Schema
            </h5>
            <p>{`${globalSettingDetails?.schema || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Tax Label
            </h5>
            <p>{`${globalSettingDetails?.taxLabel || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Website Title
            </h5>
            <p>{`${globalSettingDetails?.websiteTitle || "-"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Skip Login
            </h5>
            <p>{`${globalSettingDetails?.skipLogin ? "On" : "Off"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Stock Show
            </h5>
            <p>{`${globalSettingDetails?.stockShow ? "On" : "Off"}`}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Service Fee
            </h5>
            <p>{globalSettingDetails?.serviceFee || "-"}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              SEO Title
            </h5>
            <p>{globalSettingDetails?.seoTitle || "-"}</p>
          </Col>
          <Col md="4 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              SEO Keyword
            </h5>
            <p>{globalSettingDetails?.seoKeyWord || "-"}</p>
          </Col>
          <Col md="12 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              SEO Description
            </h5>
            <p>{globalSettingDetails?.seoDesc || "-"}</p>
          </Col>
          <Col md="12 mb-3">
            <h5 className="mb-2" style={{ color: "#8d8d8d" }}>
              Invoice Terms
            </h5>
            <p>{globalSettingDetails?.invoiceTerms || "-"}</p>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default OrderSettingDetails;
