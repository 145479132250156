import React, { useState } from "react";
import LoaderContext from "./LoaderContext";

const LoaderState = (props) => {
  const [showLoader, setLoaderShow] = useState(false);
  const [progressbar, setProgressBar] = useState(false);
  return (
    <LoaderContext.Provider
      value={{ showLoader, setLoaderShow, progressbar, setProgressBar }}
    >
      {props.children}
    </LoaderContext.Provider>
  );
};

export default LoaderState;
