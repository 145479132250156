import React, { useContext } from "react";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import AttributeMasterDataTabelContainer from "./AttributeMasterDataTabelContainer";
import AttributeCreateFormPopup from "./AttributeCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  ATTRIBUTE_CHID_,
  ATTRIBUTE_List,
  SLACE,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import Modal from "../../../../reuseableComponents/Modal";
import { CreateAttribute, UpdateAttribute } from "../../../../constants";
import AttributeMasterContext from "../../../../context/AttributeMasterContext/AttributeMasterContext";
import { get_Attribute_Master } from "../../../../Apis/attributeMasterApi/AttributeMasterApi";

const AttributeMaster = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //attribute master edit condition state and data object state
    setdialogs,
    dialog,
    setIsEdit,
    isEdit,
    setFormData,
    setErrors,
  } = useContext(AttributeMasterContext);

  const history = useNavigate();

  /**
   * get attribute master List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getAttributeMaster", paramsObj],
    async () => await get_Attribute_Master(paramsObj),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          setRecord(response.pager.totalRecords);
        }
      },
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  /**
   * Open Popup and Close Popup attribute master
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      setFormData({ name: "", attributeType: null, order: "" });
      setErrors({});
    } else {
      setdialogs(condition);
      setFormData({ name: "", attributeType: null, order: "" });
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={ATTRIBUTE_CHID_} slace={SLACE} name1={ATTRIBUTE_List} />
      <AttributeMasterDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      <Modal
        hide={handleOpenandClosePopup}
        visible={dialog}
        header={isEdit ? CreateAttribute : UpdateAttribute}
        Component={<AttributeCreateFormPopup refetch={refetch} />}
      />
    </>
  );
};

export default AttributeMaster;
