import { Delete, Edit, PinDrop, Wallet } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import { TIME_ZONE } from "../../../../constants";
import { useMutation } from "react-query";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import StoreContext from "../../../../context/StoreContext/StoreContext";
import {
  Delete_Store,
  Enable_Disable_Store,
  Get_Details_Store,
} from "../../../../Apis/storeApi/StoreApi";
import { useNavigate } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";

const StoreDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
  } = useContext(StoreContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;
  const history = useNavigate();

  /**
   * get store Details
   */
  const { mutate: storeDetails, isLoading: isLoadingStoreDetails } =
    useMutation((storeId) =>
      Get_Details_Store(storeId, setFormData, setdialogs)
    );

  const GetStoreDetails = (storeId) => {
    storeDetails(storeId);
    setId(storeId);
    setIsEdit(false);
  };

  /**
   * delete store
   */
  const { mutate: storeDelete, isLoading: isLoadingDelete } = useMutation(
    (storeId) => Delete_Store(storeId, refetch)
  );

  const DeleteStore = async (storeId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      storeDelete(storeId);
    }
  };

  /**
   * enable / disable store
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_Store(formData, refetch)
  );

  const handleStoreStatusChange = (storeId, status) => {
    let formDate = {
      storeId: storeId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.storeId === storeId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 250,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.name ? params.row.name : "--"),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "address",
      headerName: "Address",
      renderCell: (params) => (params.row.address ? params.row.address : "--"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "status",
      headerName: "Status",
      renderCell: (params, index) => (
        <div className="flex justify-content-center">
          <InputSwitch
            checked={params.row.isActive === true}
            onChange={() =>
              handleStoreStatusChange(params.row.storeId, params.row.isActive)
            }
          />
        </div>
      ),
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdAt",
      headerName: "CreatedAt",
      sortable: false,
      renderCell: (params) =>
        moment(params.row.createdAt)
          .utcOffset(TIME_ZONE)
          .format("DD MMM, YYYY hh:mm"),
    },
    {
      flex: 0.1,
      minWidth: 100,
      field: "actions",
      headerName: "Actions",
      sortable: false,

      renderCell: (params) => (
        <>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                onClick={() => GetStoreDetails(params.row.storeId)}
              >
                <Edit style={{ color: "#7366ff" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Wallet History">
              <IconButton
                aria-label="edit"
                onClick={() =>
                  history(`/store-wallet-history/${params.row.storeId}`)
                }
              >
                <Wallet style={{ color: "#f59e0b" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Cluster">
              <IconButton
                aria-label="edit"
                onClick={() => history(REDIRECT_PATH.CLUSTER)}
              >
                <PinDrop style={{ color: "#f59e0b" }} />
              </IconButton>
            </Tooltip>
          </div>
          <div style={{ paddingRight: "5px" }}>
            <Tooltip title="Delete">
              <IconButton
                aria-label="delete"
                onClick={() => DeleteStore(params.row.storeId)}
              >
                <Delete style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </div>
        </>
      ),
    },
  ];
  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingStoreDetails || isLoadingDelete} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.storeId}
        addButton={true}
      />
    </Fragment>
  );
};

export default StoreDataTabelContainer;
