import React, { useContext } from "react";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import { SLACE } from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import CssContext from "../../../../context/css_context/CssContext/CssContext";
import { Button } from "primereact/button";
import { Get_Details_Inquiry } from "../../../../Apis/inquiryApi/InquiryApi";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { REDIRECT_PATH } from "../../../../Routes";

const InquiryDetails = () => {
  const { darkMode } = useContext(CssContext);
  let { inquiryId } = useParams();
  const history = useNavigate();

  /**
   * get inquiry details
   */

  let paramsObj = {
    inquiryId,
  };

  let { data: inquiryDetails } = useQuery(
    ["getInquiryDetails", paramsObj],
    async () => await Get_Details_Inquiry(paramsObj),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <>
      <Breadcrumb name={"Inquiry"} slace={SLACE} name1={"Inquiry Details"} />
      <div className="tabel-contant">
        <div className={`tabelContent ${darkMode && "dark-tabel"}`}>
          <div
            style={{
              padding: "30px",
              borderBottom: "1px solid #dfe7ef",
              paddingLeft: "45px",
              paddingRight: "45px",
            }}
            className="d-flex justify-content-between align-items-center "
          >
            <h5>{inquiryDetails?.name || "Inquiry"}</h5>
            <Button
              label="Close"
              type="button"
              outlined
              style={{ height: "40px" }}
              onClick={() => history(REDIRECT_PATH.INQUIRY_)}
            />
          </div>
          <div className="m-5">
            <div className="text-center text-900 font-bold text-4xl mb-3 ">
              {inquiryDetails?.name}
            </div>
            <div className=" text-center text-700 text-2xl mb-3">
              {inquiryDetails?.email}
            </div>
            <div className=" text-center text-700 text-1xl mb-3">
              {`${inquiryDetails?.dialCode} ${inquiryDetails?.phone}`}
            </div>
            <div
              className="border-2 border-dashed border-300 p-4 "
              style={{ minWidth: "50px" }}
            >
              <h4>{inquiryDetails?.subject}</h4>
              <p className="m-0 font-medium text-500">
                {inquiryDetails?.message}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InquiryDetails;
